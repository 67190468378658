import React from 'react';
import { BaseIconType } from '@/types';
import { colors } from '@/theme/colors';

export const MatchResultsIcon: React.FC<BaseIconType> = ({ width = '20px', height = '20px', fill = colors.white[100] }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
			<path
				d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM4 5H20V19H4V5ZM6 7H18V9H6V7ZM6 11H10V13H6V11ZM6 15H14V17H6V15ZM16 11H18V13H16V11ZM16 15H18V17H16V15Z"
				fill={fill}
			/>
		</svg>
	);
};
