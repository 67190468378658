export const TABS = [
	{
		id: 1,
		title: 'Refills',
	},
	{
		id: 2,
		title: 'Withdrawals',
	},
];

export const WITHDRAWALS_RADIO_BUTTONS = [
	{
		id: 1,
		title: 'Available',
	},
	{
		id: 2,
		title: 'Cancelled',
	},
];

export const TABLE_TH = [
	{
		id: 1,
		title: 'First Name:',
	},
	{
		id: 2,
		title: 'Last Name:',
	},
	{
		id: 3,
		title: 'Email:',
	},
	{
		id: 4,
		title: 'User ID:',
	},
	{
		id: 5,
		title: 'Nearest withdrawal status:',
	},
];

export const USERS_TABLE_TH = [
	{
		id: 1,
		title: 'Id:',
	},
	{
		id: 2,
		title: 'Name:',
	},
	{
		id: 3,
		title: 'User name:',
	},
	{
		id: 4,
		title: 'Address:',
	},
	{
		id: 5,
		title: 'Phone:',
	},
	{
		id: 6,
		title: 'Email:',
	},
	{
		id: 7,
		title: 'Credits:',
	},
	{
		id: 8,
		title: 'Wins / Losses:',
	},
	{
		id: 9,
		title: 'Role:',
	},

	{
		id: 10,
		title: 'Status:',
	},
	{
		id: 11,
		title: 'Active / Blocked:',
	},
	{
		id: 12,
		title: 'Results:',
	},
];
