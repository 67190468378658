import React from 'react';
import { BaseIconType } from '@/types';

export const UserActiveIcon: React.FC<BaseIconType> = ({ width = '24px', height = '24px', fill = 'white' }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="7.5" r="3" fill={fill} />
			<path d="M19.5 20.5c-.475-9.333-14.525-9.333-15 0" fill={fill} />
		</svg>
	);
};
