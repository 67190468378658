import React, { useState } from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	InputProps,
	FormControlProps,
	InputGroup,
	FormLabelProps,
	IconButton,
} from '@chakra-ui/react';
import { EyeIcon } from '../../assets/icons';
import { colors } from '@/theme/colors';

interface IInputText extends InputProps {
	errorMsg?: string;
	label?: string;
	formControlProps?: FormControlProps;
	labelProps?: FormLabelProps;
	isPassword?: boolean;
	type?: string;
	placeholderColor?: string;
	eyeColor?: string;
}

export const InputText = React.forwardRef<null, IInputText>(
	(
		{
			label = '',
			errorMsg,
			formControlProps,
			labelProps,
			isPassword = false,
			type = 'text',
			placeholderColor = 'white',
			eyeColor,
			...rest
		},
		ref,
	) => {
		const [showPassword, setShowPassword] = useState(false);
		return (
			<FormControl variant="floating" isInvalid={!!errorMsg} id={label} {...formControlProps}>
				{label && (
					<FormLabel fontSize="14px" lineHeight="17px" fontWeight={500} mb="8px" color="white" {...labelProps}>
						{label}
					</FormLabel>
				)}
				<InputGroup
					border="none"
					boxShadow="none"
					_hover={{
						boxShadow: 'none',
					}}
				>
					<Input
						ref={ref}
						type={isPassword ? (showPassword ? 'text' : 'password') : type}
						p={isPassword ? '8px 22px 8px 0px' : '8px 0px'}
						h="35px"
						py="8px"
						color="white"
						fontSize="16px"
						lineHeight="19px"
						border="none"
						borderRadius="none"
						borderBottom={`1px solid ${colors.green[100]}`}
						bg="transparent"
						_placeholder={{
							opacity: 0.5,
						}}
						_hover={{
							borderBottomColor: colors.green[400],
						}}
						_focusVisible={{
							borderBottomColor: colors.primary,
							boxShadow: 'none',
						}}
						_invalid={{ borderBottomColor: 'red', boxShadow: 'none' }}
						focusBorderColor="none"
						{...rest}
					/>
					{isPassword ? (
						<IconButton
							variant="unstyled"
							aria-label="Hide/show password"
							position="absolute"
							right="16px"
							h="35px"
							w="24px"
							minW="24px"
							_focusVisible={{}}
							onClick={() => setShowPassword(prev => !prev)}
						>
							<EyeIcon width="15px" height="15px" fill={eyeColor} />
						</IconButton>
					) : null}
				</InputGroup>

				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
