import { extendTheme, theme as base } from '@chakra-ui/react';
import { colors } from './colors';
import { Text, Button, Heading } from './components';

export const theme = extendTheme({
	config: { useSystemColorMode: false },
	colors,
	styles: {
		global: {
			body: {
				bg: colors.bgMain,
				color: 'white',
			},
		},
	},
	fonts: {
		body: `Inter, ${base.fonts.body}`,
	},
	components: {
		Text,
		Button,
		Heading,
	},
});
