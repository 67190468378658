import React, { ReactElement } from 'react';
import { Box, BoxProps, Button, Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import { colors } from '@/theme/colors';

interface IDataCard {
	onOpenModal: () => void;
	cardTitle: string;
	historyTitle: string;
	cardValue: string;
	children: ReactElement;
	buttonText: string;
	currentValueLoading?: boolean;
	buttonIcon: ReactElement;
	containerProps?: BoxProps;
}

export const DataCard: React.FC<IDataCard> = ({
	onOpenModal,
	cardTitle,
	historyTitle,
	cardValue,
	children,
	buttonText,
	currentValueLoading,
	buttonIcon,
	containerProps,
}) => {
	return (
		<Box p="16px" borderRadius="10px" bg={colors.green[300]} border={`1px solid ${colors.green[200]}`} {...containerProps}>
			<Flex direction="column" rowGap="16px">
				<Flex justify="space-between">
					<VStack spacing="16px" align="flex-start">
						<Text>{cardTitle}</Text>
						{currentValueLoading ? (
							<Skeleton h="39px" width="110px" borderRadius="10px" startColor={colors.green[300]} endColor={colors.primary} />
						) : (
							<Text fontSize="32px" lineHeight="39px" fontWeight={700} color={colors.primary}>
								{cardValue}
							</Text>
						)}
					</VStack>
					<Button variant="filled" alignSelf="flex-end" columnGap="8px" pr="24px" onClick={onOpenModal}>
						{buttonIcon}
						<Text>{buttonText}</Text>
					</Button>
				</Flex>

				<Box py="16px" borderBottom={`2px solid ${colors.primary}`}>
					<Text color={colors.primary} _firstLetter={{ textTransform: 'capitalize' }}>
						{historyTitle} history
					</Text>
				</Box>
				{children}
			</Flex>
		</Box>
	);
};
