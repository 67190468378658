import { call, put, takeEvery } from 'redux-saga/effects';
import API from '@/api/api.base';
import { Alerter } from '@/utils';
import { ApiResponse, ApiResponseEmpty, SagaError } from '@/types';
import {
	getWithdrawManagementRequest,
	getWithdrawManagementSuccess,
	getWithdrawManagementErrors,
	getUserRefillsRequest,
	getUserRefillsSuccess,
	getUserRefillsErrors,
	getUserWithdrawalsRequest,
	getUserWithdrawalsSuccess,
	getUserWithdrawalsErrors,
	updateWithdrawalStatusRequest,
	updateWithdrawalStatusSuccess,
	updateWithdrawalStatusErrors,
} from './reducer';
import { UserRefillsTableResponse, UserWithdrawalsTableResponse, WithdrawalsManagementResponse } from '../types';

function* workerGetWithdrawManagement(action: ReturnType<typeof getWithdrawManagementRequest>) {
	const { currentPage, pageSize, getMore } = action.payload;
	try {
		const result: ApiResponse<WithdrawalsManagementResponse> = yield call(
			API.get,
			`/api/v1/admin/withdrawal/users/management?CurrentPage=${currentPage}&PageSize=${pageSize}`,
			{},
		);

		if (result.success) {
			yield put(
				getWithdrawManagementSuccess({
					data: result.value,
					getMore,
				}),
			);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getWithdrawManagementErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerGetUserRefills(action: ReturnType<typeof getUserRefillsRequest>) {
	const { currentPage, pageSize, userId, getMore } = action.payload;
	try {
		const result: ApiResponse<UserRefillsTableResponse> = yield call(
			API.get,
			`/api/v1/admin/withdrawal/users/refill?UserId=${userId}&CurrentPage=${currentPage}&PageSize=${pageSize}`,
			action.payload,
		);

		if (result.success) {
			yield put(
				getUserRefillsSuccess({
					data: result.value,
					getMore,
				}),
			);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getUserRefillsErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerGetUserWithdrawals(action: ReturnType<typeof getUserWithdrawalsRequest>) {
	const { currentPage, pageSize, userId, getMore } = action.payload;
	try {
		const result: ApiResponse<UserWithdrawalsTableResponse> = yield call(
			API.get,
			`/api/v1/admin/withdrawal/users?UserId=${userId}&CurrentPage=${currentPage}&PageSize=${pageSize}`,
			{},
		);
		if (result.success) {
			yield put(
				getUserWithdrawalsSuccess({
					data: result.value,
					getMore,
				}),
			);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getUserWithdrawalsErrors(result.errors));
		}
	} catch (error) {
		console.log(error);
	}
}

function* workerUpdateWithdrawalStatus(action: ReturnType<typeof updateWithdrawalStatusRequest>) {
	const { userId } = action.payload;
	try {
		const result: ApiResponseEmpty = yield call(API.put, `/api/v1/admin/withdrawal/users/${userId}/withdraw-cancel`, {});

		if (result.success) {
			yield put(updateWithdrawalStatusSuccess(action.payload));
			Alerter.success(result.messages[0]?.message);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(updateWithdrawalStatusErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

export default function* watchWithdrawManagementPageSaga() {
	yield takeEvery(getWithdrawManagementRequest.type, workerGetWithdrawManagement);
	yield takeEvery(getUserRefillsRequest.type, workerGetUserRefills);
	yield takeEvery(getUserWithdrawalsRequest.type, workerGetUserWithdrawals);
	yield takeEvery(updateWithdrawalStatusRequest.type, workerUpdateWithdrawalStatus);
}
