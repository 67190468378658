import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { ModalFooterButtons, QuantitySelector } from '@/components';
import { colors } from '@/theme/colors';

interface IPercentageEditTab {
	onSubmitHandler: (commissionValue: number) => void;
	commissionValue: number;
	loading: boolean;
	commissionTypeId: number;
	isModalOpen: boolean;
	onClose: () => void;
}

export const PercentageEditTab: React.FC<IPercentageEditTab> = ({
	onSubmitHandler,
	commissionValue,
	loading,
	commissionTypeId,
	isModalOpen,
	onClose,
}) => {
	const { handleSubmit, reset, control, watch } = useForm<{ commissionPercentage: string }>({
		defaultValues: {
			commissionPercentage: commissionValue ? `${commissionValue / 100}` : '',
		},
	});

	const commissionPercentageValue = watch('commissionPercentage');

	const onSubmit = (data: { commissionPercentage: string }) => {
		const { commissionPercentage } = data;
		onSubmitHandler(+commissionPercentage * 100);
	};

	useEffect(() => {
		if (isModalOpen || commissionTypeId) {
			reset({
				commissionPercentage: commissionValue ? `${commissionValue / 100}` : '',
			});
		}
	}, [isModalOpen, commissionTypeId]);

	const commissionLabel = `${commissionValue ? 'New ' : ''}Commission:`;
	const isSubmitDisabled = !commissionPercentageValue || `${commissionValue / 100}` === commissionPercentageValue;
	const currentCommissionValue = `${commissionValue ? commissionValue / 100 : 0}%`;
	return (
		<Box>
			{commissionValue ? (
				<Box mt="24px">
					<Text>Current commission:</Text>
					<Text mt="16px" fontSize="32px" lineHeight="39px" color={colors.primary} fontWeight={700}>
						{currentCommissionValue}
					</Text>
				</Box>
			) : null}
			<Box mt="8px">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Text mt="24px">{commissionLabel}</Text>

					<Controller
						control={control}
						name={'commissionPercentage'}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<QuantitySelector
								value={value}
								onChange={onChange}
								containerProps={{
									mt: '16px',
								}}
							/>
						)}
					/>

					<ModalFooterButtons
						successButtonProps={{
							type: 'submit',
							isLoading: loading,
							isDisabled: isSubmitDisabled,
						}}
						cancelButtonProps={{
							onClick: onClose,
						}}
						containerProps={{
							mt: '24px',
						}}
					/>
				</form>
			</Box>
		</Box>
	);
};
