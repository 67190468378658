import React from 'react';
import { GridItem, GridItemProps, Text, TextProps } from '@chakra-ui/react';

interface ITUsersTableCell {
	title: string | number | React.ReactNode;
	containerProps?: GridItemProps;
	textProps?: TextProps;
	noOfLines?: number;
}

export const UsersTableCell: React.FC<ITUsersTableCell> = ({ title, containerProps, textProps, noOfLines = 1 }) => {
	return (
		<GridItem bg="transparent" py="16px" {...containerProps}>
			<Text {...textProps} noOfLines={noOfLines}>
				{title}
			</Text>
		</GridItem>
	);
};
