import { ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
	baseStyle: {
		fontWeight: '700',
		color: 'white',
	},
	variants: {
		h1: {
			fontSize: '32px',
			lineHeight: '48px',
		},
		h2: {
			fontSize: '20px',
			lineHeight: '24px',
		},
	},
};
