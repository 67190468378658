import React from 'react';
import { Box, Flex, FlexProps, IconButton, VStack } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { colors } from '@/theme/colors';
import { NavLinkItems } from '@/constants';
import { NavItem } from './NavItem';
import { LogoutButton } from './LogoutButton';

interface MobileProps extends FlexProps {
	onLogout: () => void;
	onOpen: () => void;
	pathname: string;
	logoutLoading: boolean;
}

export const MobileNav: React.FC<MobileProps> = ({ onOpen, onLogout, pathname, logoutLoading, ...rest }) => {
	return (
		<Flex
			ml={{ base: 0, lg: 60 }}
			px={{ base: 4, lg: 24 }}
			py="16px"
			height="100vh"
			position="fixed"
			direction="column"
			justifyContent="flex-start"
			alignItems="center"
			rowGap="30px"
			zIndex={700}
			bg={colors.bgMain}
			borderRight={`1px solid ${colors.gray[100]}`}
			{...rest}
		>
			<IconButton
				variant="outline"
				onClick={onOpen}
				aria-label="open menu"
				boxSize="30px"
				minW="30px"
				transition="opacity 0.3s ease"
				icon={<HamburgerIcon color="white" fontSize="16px" />}
				_hover={{ opacity: 0.75 }}
				_active={{}}
			/>
			<VStack spacing="36px" mt="0px" align={{ base: 'center', lg: 'stretch' }}>
				{NavLinkItems.map(it => (
					<NavItem
						key={it.id}
						name={it.name}
						Icon={it.Icon}
						ActiveIcon={it.ActiveIcon}
						location={pathname}
						path={it.path}
						onlyIcon={true}
					/>
				))}
			</VStack>
			<Box mt="auto" w="100%" textAlign="center">
				<LogoutButton onLogout={onLogout} loading={logoutLoading} onlyIcon={true} />
			</Box>
		</Flex>
	);
};
