import React from 'react';
import { Container, Flex, useDisclosure } from '@chakra-ui/react';
import { CommissionSection, WithdrawalsSection } from './components';
import { EditCommissionModal, WithdrawalModal } from './modals';
import { useDispatch } from 'react-redux';
import {
	getBalanceRequest,
	getCommissionInfoRequest,
	getWithdrawalsInfoRequest,
	resetCommissionInfoState,
	resetUpdateCommissionState,
	resetUpdateWithdrawalsState,
	resetWithdrawalsInfoState,
	updateCommissionRequest,
	updateWithdrawalsRequest,
} from './store/reducer';
import { InfinitePaginationPayload } from '@/types';
import { CommissionEditPayload } from './types';

const Commission: React.FC = () => {
	const dispatch = useDispatch();

	const { isOpen: isOpenEditCommissionModal, onOpen: onOpenEditCommissionModal, onClose: onCloseEditCommissionModal } = useDisclosure();
	const { isOpen: isOpenWithdrawalModal, onOpen: onOpenWithdrawalModal, onClose: onCloseWithdrawalModal } = useDisclosure();

	const onUpdateCommission = (payload: CommissionEditPayload) => dispatch(updateCommissionRequest(payload));
	const onWithdrawal = (amount: number) => dispatch(updateWithdrawalsRequest({ amount }));
	const onGetCommissionData = (payload: InfinitePaginationPayload) => dispatch(getCommissionInfoRequest(payload));
	const onResetCommissionData = () => dispatch(resetCommissionInfoState());
	const onGetWithdrawalsData = (payload: InfinitePaginationPayload) => dispatch(getWithdrawalsInfoRequest(payload));
	const onGetBalance = () => dispatch(getBalanceRequest());
	const onResetWithdrawalsData = () => dispatch(resetWithdrawalsInfoState());
	const onResetUpdateCommissionState = () => dispatch(resetUpdateCommissionState());
	const onResetUpdateWithdrawalsState = () => dispatch(resetUpdateWithdrawalsState());

	return (
		<>
			<Container maxW="100%" pt={{ base: '30px', lg: '50px' }} pb="30px">
				<Flex direction="column" rowGap="48px">
					<CommissionSection
						onOpenEditCommissionModal={onOpenEditCommissionModal}
						onGetCommissionData={onGetCommissionData}
						onResetCommissionData={onResetCommissionData}
					/>
					<WithdrawalsSection
						onOpenWithdrawalModal={onOpenWithdrawalModal}
						onGetWithdrawalsData={onGetWithdrawalsData}
						onResetWithdrawalsData={onResetWithdrawalsData}
						onGetBalance={onGetBalance}
					/>
				</Flex>
			</Container>

			<EditCommissionModal
				isOpen={isOpenEditCommissionModal}
				onClose={onCloseEditCommissionModal}
				onSubmitHandler={onUpdateCommission}
				onResetState={onResetUpdateCommissionState}
			/>

			<WithdrawalModal
				isOpen={isOpenWithdrawalModal}
				onClose={onCloseWithdrawalModal}
				onSubmitHandler={onWithdrawal}
				onResetState={onResetUpdateWithdrawalsState}
			/>
		</>
	);
};

export default Commission;
