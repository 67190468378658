import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, RadioGroup, Text } from '@chakra-ui/react';
import { colors } from '@/theme/colors';
import { CloseIcon } from '@/assets/icons';
import { useSelector } from 'react-redux';
import { RootStore } from '@/store';
import { CustomRadioButton } from '@/components';
import { WITHDRAWALS_RADIO_BUTTONS } from '../constants';
import { getNearestWithdrawalStatusProps } from '../utils';

interface IChangeWithdrawalStatusModal {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onReset: () => void;
}

export const ChangeWithdrawalStatusModal: React.FC<IChangeWithdrawalStatusModal> = ({ isOpen, onClose, onSubmit, onReset }) => {
	const [userTransactionStatus, setUserTransactionStatus] = useState('0');

	const { nearestWithdrawal } = useSelector((state: RootStore) => state.withdrawManagement.userWithdrawals);
	const { success, loading } = useSelector((state: RootStore) => state.withdrawManagement.updateWithdrawalStatus);

	const { id, withdrawalStatus } = nearestWithdrawal || {};

	useEffect(() => {
		if (withdrawalStatus?.id) {
			setUserTransactionStatus(`${withdrawalStatus.id}`);
		}
	}, [withdrawalStatus?.id]);

	useEffect(() => {
		if (success) {
			onReset();
			onClose();
		}
	}, [success]);

	const currentWithdrawalStatus = getNearestWithdrawalStatusProps(withdrawalStatus?.id);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered blockScrollOnMount>
			<ModalOverlay backdropFilter="blur(3px)" />
			<ModalContent
				overflow="hidden"
				bg={colors.green[300]}
				border={`1px solid ${colors.green[100]}`}
				borderRadius="10px"
				p="24px"
				boxShadow="none"
			>
				<ModalHeader fontWeight="500" fontSize="20px" p="0" pr="34px" color={colors.white[100]}>
					Change Status
				</ModalHeader>
				<ModalCloseButton
					top="24px"
					right="24px"
					w="30px"
					h="30px"
					bg={colors.primary}
					borderRadius="8px"
					color={colors.black[100]}
					_hover={{
						opacity: '0.75',
					}}
					_active={{}}
				>
					<CloseIcon />
				</ModalCloseButton>
				<Text mt="40px">Nearest withdrawal status:</Text>
				<Text mt="16px" variant="bodyLarge" color={currentWithdrawalStatus.color}>
					{currentWithdrawalStatus.title}
				</Text>
				<Text mt="24px">Change the status:</Text>

				<RadioGroup onChange={newValue => setUserTransactionStatus(newValue)} value={userTransactionStatus}>
					<Flex mt="16px" direction="column" justifyContent="space-between" alignItems="flex-start" rowGap="16px">
						{WITHDRAWALS_RADIO_BUTTONS.map(radio => (
							<CustomRadioButton
								key={radio.id}
								value={`${radio.id}`}
								title={radio.title}
								titleProps={{
									variant: 'bodyLarge',
									lineHeight: '24px',
									ml: '8px',
								}}
							/>
						))}
					</Flex>
				</RadioGroup>

				<Flex mt="40px" columnGap="16px">
					<Button variant="border" w="full" onClick={onClose}>
						<Text>Cancel</Text>
					</Button>
					<Button variant="filled" w="full" isDisabled={userTransactionStatus === '1'} isLoading={loading} onClick={onSubmit}>
						<Text>Confirm</Text>
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
