import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HistoryItem, Loader } from '@/components';
import { normalizePrice } from '@/utils';
import { WithdrawalsActiveIcon } from '@/assets/icons';
import { colors } from '@/theme/colors';
import { VerticalScrollbarStyles } from '@/constants';
import { RootStore } from '@/store';
import { DataCard } from './DataCard';
import { InfinitePaginationPayload } from '@/types';

const pageSize = 10;

interface IWithdrawalsSection {
	onOpenWithdrawalModal: () => void;
	onGetWithdrawalsData: (payload: InfinitePaginationPayload) => void;
	onResetWithdrawalsData: () => void;
	onGetBalance: () => void;
}

export const WithdrawalsSection: React.FC<IWithdrawalsSection> = ({
	onOpenWithdrawalModal,
	onGetWithdrawalsData,
	onResetWithdrawalsData,
	onGetBalance,
}) => {
	const [page, setPage] = useState(1);

	const { data, totalCount, loading: dataLoading } = useSelector((state: RootStore) => state.commission.withdrawalsInfo);
	const { commissionAmount, loading: balanceLoading } = useSelector((state: RootStore) => state.commission.balance);
	const { success } = useSelector((state: RootStore) => state.commission.updateWithdrawals);

	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	const hasMore = useMemo(() => data.length < totalCount, [data.length, totalCount]);

	useEffect(() => {
		if (!success) {
			onGetWithdrawalsData({
				currentPage: 1,
				getMore: false,
				pageSize,
			});
			onGetBalance();
			setPage(1);
		}

		return () => {
			onResetWithdrawalsData();
		};
	}, [success]);
	useEffect(() => {
		if (page !== 1) {
			onGetWithdrawalsData({
				currentPage: page,
				getMore: true,
				pageSize,
			});
		}
	}, [page]);

	return (
		<Box>
			<Heading variant="h2" mb="32px">
				Withdrawals
			</Heading>
			<DataCard
				onOpenModal={onOpenWithdrawalModal}
				cardTitle="Available funds"
				historyTitle="Withdrawal"
				cardValue={`$${commissionAmount.toFixed(2)}`}
				currentValueLoading={balanceLoading}
				buttonText="Withdrawal"
				buttonIcon={<WithdrawalsActiveIcon fill={colors.black[100]} stroke={colors.black[100]} />}
				containerProps={{
					maxW: '720px',
				}}
			>
				<InfiniteScroll
					dataLength={data.length}
					next={() => {
						if (!dataLoading) {
							onPageChange();
						}
					}}
					hasMore={hasMore}
					loader={
						page !== 1 &&
						dataLoading && (
							<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
								<Loader centerHeight="40px" spinnerSize="sm" />
							</Flex>
						)
					}
					height="auto"
					className="infinite-scroll-section"
					style={{
						maxHeight: '230px',
						paddingRight: data.length > 3 ? '4px' : '0px',
					}}
				>
					{dataLoading && page === 1 ? (
						<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
							<Loader centerHeight="50px" spinnerSize="md" />
						</Flex>
					) : (
						<>
							{data.length ? (
								data.map((item, index) => (
									<HistoryItem
										key={index}
										date={item.createdAt}
										valueBefore={normalizePrice(item.balanceBefore)}
										valueAfter={normalizePrice(item.withdrawal)}
										titleBefore="Balance before"
										titleAfter="Withdrawal"
										balanceAfterValue={normalizePrice(item.balanceAfter)}
										containerProps={{
											mt: index === 0 ? '0px' : '4px',
										}}
									/>
								))
							) : (
								<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
									<Text>No Data</Text>
								</Flex>
							)}
						</>
					)}
				</InfiniteScroll>
			</DataCard>
		</Box>
	);
};
