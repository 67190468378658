import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { InputText, ModalFooterButtons } from '@/components';
import { CommissionValueType } from '@/enums';
import { digitsAndDots, normalizePrice } from '@/utils';
import { colors } from '@/theme/colors';

interface IFixedRateEditTab {
	commissionValue: number;
	loading: boolean;
	onSubmitHandler: (commissionValue: number) => void;
	commissionTypeId: number;
	isModalOpen: boolean;
	onClose: () => void;
}

export const FixedRateEditTab: React.FC<IFixedRateEditTab> = ({
	onSubmitHandler,
	commissionValue,
	loading,
	commissionTypeId,
	isModalOpen,
	onClose,
}) => {
	const { handleSubmit, reset, control, watch } = useForm<{ commissionFixed: string }>({
		defaultValues: {
			commissionFixed: commissionValue ? `${commissionValue / 100}` : '',
		},
	});

	const commissionFixedValue = watch('commissionFixed');

	const onSubmit = (data: { commissionFixed: string }) => {
		const { commissionFixed } = data;
		const newValue = +commissionFixed * 100;
		onSubmitHandler(newValue);
	};

	useEffect(() => {
		if (isModalOpen || commissionTypeId) {
			reset({
				commissionFixed: commissionValue ? `${commissionValue / 100}` : '',
			});
		}
	}, [isModalOpen, commissionTypeId]);

	const commissionLabel = `${commissionValue ? 'New ' : ''}Commission:`;
	const isCommissionOld = `${commissionValue / 100}` === commissionFixedValue;
	const isSubmitDisabled = !commissionFixedValue || isCommissionOld;
	const formattedCurrentCommission = normalizePrice(commissionValue);

	return (
		<Box>
			{commissionValue ? (
				<Box mt="24px">
					<Text>Current commission:</Text>
					<Text mt="16px" fontSize="32px" lineHeight="39px" color={colors.primary} fontWeight={700}>
						{formattedCurrentCommission}
					</Text>
				</Box>
			) : null}
			<Box mt="8px">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Text mt="24px">{commissionLabel}</Text>

					<Controller
						control={control}
						name={'commissionFixed'}
						render={({ field: { value, onChange }, fieldState: { error } }) => {
							const newValue = value === '' ? '' : `$${value}`;
							return (
								<InputText
									value={newValue}
									onChange={event => {
										const inputValue = digitsAndDots(event.target.value);
										onChange(inputValue);
									}}
									type="text"
									placeholder="USD"
									errorMsg={error?.message}
								/>
							);
						}}
					/>

					<ModalFooterButtons
						successButtonProps={{
							type: 'submit',
							isLoading: loading,
							isDisabled: isSubmitDisabled,
						}}
						cancelButtonProps={{
							onClick: onClose,
						}}
						containerProps={{
							mt: '24px',
						}}
					/>
				</form>
			</Box>
		</Box>
	);
};
