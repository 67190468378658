import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, CloseButton, Flex, FlexProps, Heading, VStack } from '@chakra-ui/react';
import { NavLinkItems } from '@/constants';
import { NavItem } from './NavItem';
import { colors } from '@/theme/colors';
import { LogoutButton } from './LogoutButton';
import { CustomSwitch } from '@/components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '@/store';
import { getAdminGameConfigRequest, updateAdminGameConfigRequest } from '@/pages/Users/store/reducer';

interface ISidebarContent extends FlexProps {
	onLogout: () => void;
	onClose: () => void;
	onlyIcon?: boolean;
	logoutLoading: boolean;
}

export const SidebarContent: React.FC<ISidebarContent> = ({ onClose, onLogout, onlyIcon, logoutLoading, ...rest }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { botsEnabled } = useSelector((state: RootStore) => state.users.users);

	const handleToggle = () => {
		dispatch(updateAdminGameConfigRequest());
	};

	useEffect(() => {
		dispatch(getAdminGameConfigRequest());
	}, []);

	return (
		<Flex
			direction="column"
			alignItems={{
				base: 'space-between',
				lg: 'center',
			}}
			flexDir="column"
			align="stretch"
			bg={colors.bgMain}
			h="100vh"
			p="24px 24px 40px 24px"
			w={{ base: 'full', lg: '310px' }}
			pos="fixed"
			overflowY="auto"
			sx={{
				'&::-webkit-scrollbar': {
					width: 0,
				},
				'&::-webkit-scrollbar-track': {
					width: 0,
				},
				'&::-webkit-scrollbar-thumb': {
					width: 0,
				},
			}}
			{...rest}
		>
			<Box display={{ base: 'none', lg: 'block' }} py="5px">
				<RouterLink to={'/'}>
					<Heading variant="h2" fontWeight={600}>
						YOLOTYCOON
					</Heading>
				</RouterLink>
			</Box>
			<Box
				mt="24px"
				display={{ base: onlyIcon ? 'none' : 'block', lg: 'block' }}
				h="1px"
				w="100%"
				maxW="400px"
				bg="linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 51.4%, rgba(255, 255, 255, 0.00) 100%)"
			/>
			<Flex display={{ base: 'flex', lg: 'none' }} direction="column" alignItems="stretch" position="relative">
				<Flex mb={6} justifyContent="center" alignItems="center" mx={{ base: 0, lg: 4 }}>
					<Flex>
						<RouterLink to={'/'}>
							<Heading variant="h2" fontWeight={600}>
								YOLOTYCOON
							</Heading>
						</RouterLink>
					</Flex>
					<CloseButton position="absolute" right="0px" top="0px" color="white" onClick={onClose} />
				</Flex>
			</Flex>
			<VStack
				spacing={'24px'}
				pl="24px"
				mt={'32px'}
				align={{ base: 'flex-start', lg: 'stretch' }}
				alignSelf="flex-start"
				w={{ base: undefined, lg: 'auto' }}
			>
				{NavLinkItems.map(it => (
					<NavItem key={it.id} name={it.name} path={it.path} Icon={it.Icon} ActiveIcon={it.ActiveIcon} location={location.pathname} />
				))}
			</VStack>
			<Box mt="auto" w="100%" textAlign="center" mb={'10px'}>
				{botsEnabled !== null && <CustomSwitch isChecked={botsEnabled} onToggle={handleToggle} />}
			</Box>
			<Box mt="auto" w="100%" textAlign="center">
				<LogoutButton onLogout={onLogout} loading={logoutLoading} />
			</Box>
		</Flex>
	);
};
