import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SignInPayload, SignInResponse, PayloadError, ResetPasswordPayload} from '@/types';

interface InitialState extends SignInResponse {
	loading: boolean;
	errors: PayloadError[];
	forgotPassword: {
		isSuccess: boolean;
		loading: boolean;
		errors: PayloadError[];
	};
}

const initialState: InitialState = {
	loading: false,
	errors: [],
	token: {
		accessToken: '',
		accessTokenExpiration: '',
		refreshToken: '',
	},
	user: {
		email: '',
		firstName: '',
		id: 0,
		lastName: '',
		roles: [],
		userName: '',
	},
	forgotPassword: {
		isSuccess: false,
		loading: false,
		errors: [],
	},
};

const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signInRequest: (state, _action: PayloadAction<SignInPayload>) => {
			state.loading = true;
			state.errors = [];
		},
		signInSuccess: (state, action: PayloadAction<SignInResponse>) => {
			return { ...state, loading: false, ...action.payload };
		},
		signInError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},

		refreshToken: (
			state,
			action: PayloadAction<{
				accessToken: string;
				accessTokenExpiration: string;
				refreshToken: string;
			}>,
		) => {
			state.token = action.payload;
		},
		logoutRequest: state => {
			state.loading = true;
		},
		logoutSuccess: () => {
			return initialState;
		},

		clearAuthLoading: state => {
			state.loading = false;
			// state.errors = [];
		},

		resetPasswordEmailRequest: (state, action: PayloadAction<{ email: string }>) => {
			state.loading = true;
			state.errors = [];
		},
		resetPasswordEmailSuccess: state => {
			state.loading = false;
		},
		resetPasswordEmailError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},

		resetPasswordRequest: (
			state,
			action: PayloadAction<ResetPasswordPayload>,
		) => {
			state.loading = true;
			state.errors = [];
		},
		resetPasswordSuccess: state => {
			state.loading = false;
		},
		resetPasswordError: (state, action: PayloadAction<PayloadError[]>) => {
			state.loading = false;
			state.errors = action.payload;
		},

		// forgotPasswordRequest: (state, action: PayloadAction<{ email: string }>) => {
		// 	state.forgotPassword.loading = true;
		// 	state.forgotPassword.errors = [];
		// },
		// forgotPasswordSuccess: state => {
		// 	state.forgotPassword.isSuccess = true;
		// 	state.forgotPassword.loading = false;
		// },
		// forgotPasswordError: (state, action: PayloadAction<PayloadError[]>) => {
		// 	state.forgotPassword.loading = false;
		// 	state.forgotPassword.errors = action.payload;
		// },
		//
		// resetForgotPasswordState: state => {
		// 	return {
		// 		...state,
		// 		forgotPassword: { ...initialState.forgotPassword },
		// 	};
		// },
	},
});

export const {
	signInRequest,
	signInSuccess,
	signInError,

	refreshToken,
	logoutRequest,
	logoutSuccess,

	clearAuthLoading,

	resetPasswordEmailRequest,
	resetPasswordEmailSuccess,
	resetPasswordEmailError,

	resetPasswordRequest,
	resetPasswordError,
	resetPasswordSuccess

	// forgotPasswordRequest,
	// forgotPasswordSuccess,
	// forgotPasswordError,
	// resetForgotPasswordState,
} = authReducer.actions;

export default authReducer.reducer;
