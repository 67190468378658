import {
	Flex,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from '@chakra-ui/react';
import { colors } from '@/theme/colors';
import { CloseIcon } from '@/assets/icons';
import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { STATISTIC_TH } from '@/pages/Users/helper';
import { TablesProjectRow } from '@/pages/Users/components/MatchResultsModal/TablesProjectRow';
import { convertMsToTime } from '@/utils/convertMsToTime';
import { getUserMatchHistoryRequest, resetUserMatchHistoryState } from '@/pages/Users/store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '@/store';
import { Loader } from '@/components';
import InfiniteScroll from 'react-infinite-scroll-component';

interface MatchResultsModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	userId: number | null;
}

export const MatchResultsModal: FC<MatchResultsModalProps> = ({ onClose, isOpen, userId }) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const textColor = useColorModeValue('gray.700', 'white');
	const tableTh = STATISTIC_TH;

	const { data: userMatchHistory, totalCount, loading } = useSelector((state: RootStore) => state.users.userMatchHistory);
	const hasMore = useMemo(() => userMatchHistory.length < totalCount, [userMatchHistory.length, totalCount]);

	const onResetUserMatchHistory = () => dispatch(resetUserMatchHistoryState());
	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	const onGetUserMatchHistory = (payload: { currentPage: number; userId: number; getMore: boolean }) =>
		dispatch(getUserMatchHistoryRequest(payload));

	useEffect(() => {
		if (userId) {
			onGetUserMatchHistory({ userId, currentPage: page, getMore: page !== 1 });
		}
	}, [userId, page]);

	useEffect(() => {
		if (!isOpen) {
			onResetUserMatchHistory();
			setPage(1);
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered={true} blockScrollOnMount={true}>
			<ModalOverlay backdropFilter="blur(3px)" />
			<ModalContent
				minW={'fit-content'}
				bg={colors.green[300]}
				border={`1px solid ${colors.green[100]}`}
				borderRadius="10px"
				p="24px"
				boxShadow="none"
			>
				<ModalHeader fontWeight="500" fontSize="20px" p="0" mb="15px" pr="34px" color={colors.white[100]}>
					Match Results
				</ModalHeader>
				<ModalCloseButton
					top="24px"
					right="24px"
					w="30px"
					h="30px"
					bg={colors.primary}
					borderRadius="8px"
					color={colors.black[100]}
					_hover={{
						opacity: '0.75',
					}}
					_active={{}}
				>
					<CloseIcon />
				</ModalCloseButton>
				{userMatchHistory.length ? (
					<InfiniteScroll
						dataLength={userMatchHistory.length}
						next={() => {
							if (!loading) {
								onPageChange();
							}
						}}
						hasMore={hasMore}
						loader={
							page !== 1 && loading ? (
								<Flex h="50px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
									<Loader centerHeight="40px" spinnerSize="sm" />
								</Flex>
							) : null
						}
						className="infinite-scroll-section"
						height={'500px'}
						style={{
							maxHeight: '500px',
							width: 'auto',
							paddingRight: '0px',
							overflowX: 'hidden',
						}}
					>
						<Table color={textColor}>
							<Thead>
								<Tr my=".8rem" pl="0px">
									{tableTh.map((caption, idx) => (
										<Th key={idx} color="gray.400" ps={idx === 1 ? '0px' : undefined}>
											{caption}
										</Th>
									))}
								</Tr>
							</Thead>

							<Tbody>
								{userMatchHistory.map((elem, index) => (
									<TablesProjectRow
										key={index}
										date={dayjs(elem.startTime).format('h:mm A, MMMM D')}
										kills={elem.kills}
										matchTime={convertMsToTime(dayjs(elem.endTime).diff(dayjs(elem.startTime)))}
										assists={elem.assists}
										deaths={elem.deaths}
										balance={elem.balance.toFixed(2).toString()}
										result={elem.gameResultId}
									/>
								))}
							</Tbody>
						</Table>
					</InfiniteScroll>
				) : null}
				{!loading && !userMatchHistory.length ? (
					<Text variant={'bodyLarge'} textAlign={'center'} marginY={'20px'}>
						No data...
					</Text>
				) : null}
				{loading && page === 1 ? <Loader centerProps={{ marginTop: '20px' }} /> : null}
			</ModalContent>
		</Modal>
	);
};
