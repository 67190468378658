import React from 'react';
import { GridItem, GridItemProps, Text, TextProps } from '@chakra-ui/react';

interface ITableCell {
	title: string | number;
	containerProps?: GridItemProps;
	textProps?: TextProps;
}

export const TableCell: React.FC<ITableCell> = ({ title, containerProps, textProps }) => {
	return (
		<GridItem bg="transparent" py="16px" {...containerProps}>
			<Text {...textProps} noOfLines={1}>
				{title}
			</Text>
		</GridItem>
	);
};
