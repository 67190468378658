import { call, put, takeEvery } from 'redux-saga/effects';
import API from '@/api/api.base';
import { ApiResponse, SignInResponse } from '@/types';
import { Alerter, History } from '@/utils';
import { signInRequest, signInSuccess, signInError, clearAuthLoading } from '../../reducers/auth.reducer';

function* workerSignIn(action: ReturnType<typeof signInRequest>) {
	try {
		const result: ApiResponse<SignInResponse> = yield call(API.post, '/api/v1/admin/auth/sign-in', action.payload);
		if (result.success) {
			yield put(signInSuccess(result.value));
			History.push('/');
		} else {
			yield put(signInError(result.errors));
			Alerter.error(result.errors?.[0]?.message);
		}
	} catch (err) {
		console.log(err);
	} finally {
		yield put(clearAuthLoading());
	}
}

export default function* watchSignInSaga() {
	yield takeEvery(signInRequest.type, workerSignIn);
}
