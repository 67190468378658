import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const WithdrawalsIcon: React.FC<BaseIconType> = ({
	width = '24px',
	height = '24px',
	fill = `${colors.primary}`,
	stroke = `${colors.primary}`,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
		<path d="M7 16H7.01" stroke="#1A524E" strokeWidth="2" strokeLinecap="round" />
		<path d="M4 12H10.5" stroke="#1A524E" strokeWidth="2" strokeLinecap="round" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.5747 6.12123L19.5553 8.03965C20.3339 7.89963 21.166 8.13049 21.7678 8.73224C21.8318 8.79625 21.8916 8.86286 21.9472 8.93174C21.9388 8.84243 21.9291 8.7559 21.9179 8.67221C21.8297 8.0167 21.631 7.38835 21.1213 6.87868C20.676 6.43339 20.1401 6.22544 19.5747 6.12123ZM14.5756 6L14.5554 8H14H7C6.02893 8 5.40122 8.00213 4.93871 8.06431C4.50497 8.12263 4.36902 8.21677 4.2929 8.2929C4.21677 8.36902 4.12263 8.50497 4.06431 8.93871C4.00213 9.40122 4 10.0289 4 11V16C4 16.9711 4.00213 17.5988 4.06431 18.0613C4.12263 18.495 4.21677 18.631 4.2929 18.7071C4.36902 18.7832 4.50497 18.8774 4.93871 18.9357C5.40121 18.9979 6.02893 19 7 19H17C17.9711 19 18.5988 18.9979 19.0613 18.9357C19.495 18.8774 19.631 18.7832 19.7071 18.7071C19.7832 18.631 19.8774 18.495 19.9357 18.0613C19.9979 17.5988 20 16.9711 20 16V14.0355L21.7678 12.2678C21.8525 12.183 21.93 12.0937 22 12.0005V16L22 16.0658C22.0001 16.9523 22.0001 17.7161 21.9179 18.3278C21.8297 18.9833 21.631 19.6117 21.1213 20.1213C20.6117 20.631 19.9833 20.8297 19.3278 20.9179C18.7161 21.0001 17.9523 21.0001 17.0658 21L17 21H7L6.93417 21C6.04769 21.0001 5.28387 21.0001 4.67221 20.9179C4.0167 20.8297 3.38835 20.631 2.87868 20.1213C2.36902 19.6117 2.17028 18.9833 2.08215 18.3278C1.99991 17.7161 1.99995 16.9523 2 16.0658L2 16V11L2 10.9342C1.99995 10.0477 1.99991 9.28387 2.08215 8.67221C2.17028 8.0167 2.36902 7.38835 2.87868 6.87868C3.38835 6.36902 4.0167 6.17028 4.67221 6.08215C5.28387 5.99991 6.04769 5.99995 6.93417 6L7 6H14.5756Z"
			fill={fill}
		/>
		<path
			d="M17 4V14M20 11L17.1061 13.8939C17.0475 13.9525 16.9525 13.9525 16.8939 13.8939L14 11"
			stroke={stroke}
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);
