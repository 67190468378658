import React, { useEffect } from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { colors } from '@/theme/colors';
import { digitsAndDots } from '@/utils';
import { InputText } from '@/components';
import { withdrawalAmountResolver } from '@/validation';
import { CloseIcon } from '@/assets/icons';
import { useSelector } from 'react-redux';
import { RootStore } from '@/store';

interface IWithdrawalModal {
	isOpen: boolean;
	onClose: () => void;
	onSubmitHandler: (amount: number) => void;
	onResetState: () => void;
}

export const WithdrawalModal: React.FC<IWithdrawalModal> = ({ isOpen, onClose, onResetState, onSubmitHandler }) => {
	const { commissionAmount } = useSelector((state: RootStore) => state.commission.balance);
	const { loading, success } = useSelector((state: RootStore) => state.commission.updateWithdrawals);

	const formattedFunds = `$${commissionAmount.toFixed(2)}`;

	const { handleSubmit, watch, reset, control } = useForm<{ amount: string }>({
		defaultValues: {
			amount: '',
		},
		resolver: withdrawalAmountResolver,
	});

	const amount = watch('amount');

	useEffect(() => {
		if (isOpen) {
			reset({
				amount: '',
			});
		}
	}, [isOpen]);

	useEffect(() => {
		if (success) {
			onClose();
			onResetState();
		}
	}, [success]);

	const onSubmit = (data: { amount: string }) => {
		const { amount } = data;
		onSubmitHandler(+amount * 100);
	};

	const disableSubmit = !amount.length || +amount > commissionAmount;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered blockScrollOnMount>
			<ModalOverlay backdropFilter="blur(3px)" />
			<ModalContent
				overflow="hidden"
				bg={colors.green[300]}
				border={`1px solid ${colors.green[100]}`}
				borderRadius="10px"
				p="24px"
				boxShadow="none"
			>
				<ModalHeader fontWeight="500" fontSize="20px" p="0" pr="34px" color={colors.white[100]}>
					Withdrawal
				</ModalHeader>
				<ModalCloseButton
					top="24px"
					right="24px"
					w="30px"
					h="30px"
					bg={colors.primary}
					borderRadius="8px"
					color={colors.black[100]}
					_hover={{
						opacity: '0.75',
					}}
					_active={{}}
				>
					<CloseIcon />
				</ModalCloseButton>
				<Text mt="40px">Available funds:</Text>
				<Text mt="16px" fontSize="32px" lineHeight="39px" color={colors.primary} fontWeight={700}>
					{formattedFunds}
				</Text>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name={'amount'}
						render={({ field: { value, onChange }, fieldState: { error } }) => {
							const newValue = value === '' ? '' : '$' + value;
							return (
								<InputText
									value={newValue}
									onChange={event => {
										const inputValue = digitsAndDots(event.target.value);
										onChange(inputValue);
									}}
									type="text"
									label="Withdrawal amount:"
									placeholder="USD"
									errorMsg={error?.message}
									formControlProps={{
										mt: '24px',
									}}
								/>
							);
						}}
					/>

					<Flex mt="40px" columnGap="16px">
						<Button variant="border" w="full" onClick={onClose}>
							<Text>Cancel</Text>
						</Button>
						<Button type="submit" variant="filled" w="full" isLoading={loading} isDisabled={disableSubmit}>
							<Text>Confirm</Text>
						</Button>
					</Flex>
				</form>
			</ModalContent>
		</Modal>
	);
};
