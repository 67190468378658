import React, { useMemo } from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '@/components';
import { colors } from '@/theme/colors';
import { CustomHorizontalScrollStyles } from '@/constants';
import { UsersTableCell } from './UsersTableCell';
import { UsersTableRow } from './UsersTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '@/store';
import { USERS_TABLE_TH } from '@/pages/WithdrawManagement/constants';
import { UsersItem } from '@/pages/Users/types';
import { updateUserBlockRequest, updateUserRequest } from '@/pages/Users/store/reducer';

interface IUsersTableData {
	page: number;
	onPageChange: () => void;
	onMatchResultsModalOpen: (userId: number) => void;
}

export const UsersTableData: React.FC<IUsersTableData> = ({ page, onPageChange, onMatchResultsModalOpen }) => {
	const dispatch = useDispatch();
	const { data: users, totalCount, loading } = useSelector((state: RootStore) => state.users.users);

	const hasMore = useMemo(() => users.length < totalCount, [users.length, totalCount]);

	const handleSelectItemChange = (userId: number, isBot: boolean) => {
		dispatch(updateUserRequest({ userId: userId, isBot }));
	};

	const handleSelectItemChangeState = (userId: number, isBlocked: boolean) => {
		dispatch(updateUserBlockRequest({ userId: userId, isBlocked }));
	};

	const renderTh = USERS_TABLE_TH.map(th => (
		<UsersTableCell
			key={th.id}
			title={th.title}
			textProps={{
				variant: 'bodyMedium',
				textTransform: 'none',
				color: colors.primary,
			}}
		/>
	));
	return (
		<Box w="100%" overflowX="auto" pb="4px" sx={CustomHorizontalScrollStyles}>
			<Grid
				templateColumns="repeat(12, minmax(100px, 1fr))"
				gap="50px"
				px="16px"
				bg="black"
				minW={'fit-content'}
				borderRadius="10px"
				mb="4px"
			>
				{renderTh}
			</Grid>
			<InfiniteScroll
				dataLength={users.length}
				next={() => {
					if (!loading) {
						onPageChange();
					}
				}}
				hasMore={hasMore}
				loader={
					page !== 1 && loading ? (
						<Flex h="50px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
							<Loader centerHeight="40px" spinnerSize="sm" />
						</Flex>
					) : null
				}
				height="500px"
				className="infinite-scroll-section"
				style={{
					maxHeight: 'calc(100vh - 280px)',
					minWidth: 'fit-content',
					paddingRight: '0px',
					overflowX: 'hidden',
				}}
			>
				{loading && page === 1 ? (
					<GridItem colSpan={11} mt="4px" borderRadius="10px" overflow="hidden">
						<Loader centerHeight="50px" spinnerSize="md" />
					</GridItem>
				) : (
					<>
						{users.map((item: UsersItem, i) => (
							<UsersTableRow
								key={i}
								onModalOpen={() => {
									onMatchResultsModalOpen(item.id);
								}}
								onSelectItemChange={isBot => handleSelectItemChange(item.id, isBot)}
								onSelectItemChangeState={isBlocked => handleSelectItemChangeState(item.id, isBlocked)}
								{...item}
							/>
						))}
					</>
				)}
			</InfiniteScroll>
		</Box>
	);
};
