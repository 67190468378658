import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
	baseStyle: {
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '18px',
		color: 'white',
	},
	variants: {
		bodyMedium: {
			fontSize: '12px',
			lineHeight: '15px',
		},
		bodyLarge: {
			fontSize: '16px',
			lineHeight: '21px',
		},
	},
};
