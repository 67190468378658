import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const signInSchema = yup.object({
	login: yup.string().required(ErrorMessages.REQUIRED),
	password: yup.string().required(ErrorMessages.REQUIRED),
});

export const signInResolver = yupResolver(signInSchema);
