import { call, put, takeEvery } from 'redux-saga/effects';
import API from '@/api/api.base';
import { Alerter } from '@/utils';
import { ApiResponse, SagaError } from '@/types';
import { UserMatchHistoryResponse, UsersResponse } from '../types';
import {
	getAdminGameConfigErrors,
	getAdminGameConfigRequest,
	getAdminGameConfigSuccess,
	getUserMatchHistoryErrors,
	getUserMatchHistoryRequest,
	getUserMatchHistorySuccess,
	getUsersErrors,
	getUsersRequest,
	getUsersSuccess,
	updateAdminGameConfigErrors,
	updateAdminGameConfigRequest,
	updateAdminGameConfigSuccess,
	updateUserBlockErrors,
	updateUserBlockRequest,
	updateUserBlockSuccess,
	updateUserErrors,
	updateUserRequest,
	updateUserSuccess,
} from '@/pages/Users/store/reducer';

function* workerGetUsers(action: ReturnType<typeof getUsersRequest>) {
	const { currentPage, pageSize, nameSearch, emailSearch, getMore } = action.payload;
	try {
		const result: ApiResponse<UsersResponse> = yield call(API.get, '/api/v1/admin/users', {
			currentPage: currentPage,
			pageSize: pageSize,
			nameSearch: nameSearch ? nameSearch : undefined,
			emailSearch: emailSearch ? emailSearch : undefined,
		});

		if (result.success) {
			yield put(
				getUsersSuccess({
					data: result.value,
					getMore,
				}),
			);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getUsersErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerGetUserMatchHistory(action: ReturnType<typeof getUserMatchHistoryRequest>) {
	const { userId, currentPage, getMore } = action.payload;
	try {
		const result: ApiResponse<UserMatchHistoryResponse> = yield call(API.get, `/api/v1/admin/users/${userId}/match-history`, {
			currentPage,
			pageSize: 10,
		});

		if (result.success) {
			yield put(getUserMatchHistorySuccess({ gameStatistics: result.value.gameStatistics, count: result.value.count, getMore }));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getUserMatchHistoryErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerUpdateUser(action: ReturnType<typeof updateUserRequest>) {
	const { userId, isBot } = action.payload;

	try {
		const result: ApiResponse<UsersResponse> = yield call(API.patch, `/api/v1/admin/users/${userId}`, { isBot });

		if (result.success) {
			yield put(updateUserSuccess({ userId, isBot }));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(updateUserErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}
function* workerUserBlock(action: ReturnType<typeof updateUserBlockRequest>) {
	const { userId, isBlocked } = action.payload;

	try {
		const result: ApiResponse<UsersResponse> = yield call(API.patch, `/api/v1/admin/users/${userId}/block`, { isBlocked });

		if (result.success) {
			yield put(updateUserBlockSuccess({ userId, isBlocked }));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(updateUserBlockErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerGetAdminGameConfig(action: ReturnType<typeof getAdminGameConfigRequest>) {
	try {
		const result: ApiResponse<{ botsEnabled: boolean }> = yield call(API.get, '/api/v1/admin/config', {});

		if (result.success) {
			yield put(getAdminGameConfigSuccess(result.value));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getAdminGameConfigErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerUpdateAdminGameConfig(action: ReturnType<typeof updateAdminGameConfigRequest>) {
	try {
		const result: ApiResponse<{ botsEnabled: boolean }> = yield call(API.patch, '/api/v1/admin/config', {});

		if (result.success) {
			console.log(result.value);
			yield put(updateAdminGameConfigSuccess(result.value));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(updateAdminGameConfigErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

export default function* watchUsersPageSaga() {
	yield takeEvery(getUsersRequest.type, workerGetUsers);
	yield takeEvery(getUserMatchHistoryRequest.type, workerGetUserMatchHistory);
	yield takeEvery(updateUserRequest.type, workerUpdateUser);
	yield takeEvery(updateUserBlockRequest.type, workerUserBlock);
	yield takeEvery(getAdminGameConfigRequest.type, workerGetAdminGameConfig);
	yield takeEvery(updateAdminGameConfigRequest.type, workerUpdateAdminGameConfig);
}
