import React from 'react';
import { Tr, Td, Flex, Text, Icon, Box } from '@chakra-ui/react';
import { FaCrown, FaSkullCrossbones } from 'react-icons/fa';
import { IoExitOutline } from 'react-icons/io5';
import { MatchResultEnum } from '@/constants';

interface ITablesProjectRowProps {
	date: string;
	matchTime: string;
	kills: number;
	deaths: number;
	balance: string;
	result?: number;
	assists: number;
}

export const TablesProjectRow: React.FC<ITablesProjectRowProps> = ({ date, matchTime, kills, deaths, balance, result, assists }) => {
	const textColor = 'white';

	const getMatchResultColorAndIcon = () => {
		if (typeof result === 'undefined') {
			return { color: 'white', icon: undefined };
		}
		if (result === MatchResultEnum.Victory) {
			return { color: 'green.400', icon: FaCrown };
		} else if (result === MatchResultEnum.Defeat) {
			return { color: 'red', icon: FaSkullCrossbones };
		} else {
			return { color: 'white', icon: IoExitOutline };
		}
	};

	return (
		<Tr borderBottom="1px solid" borderColor="gray.600">
			<Td borderBottom="none">
				<Box>
					<Icon as={getMatchResultColorAndIcon().icon} color={getMatchResultColorAndIcon().color} h={'24px'} w={'24px'} me="18px" />
				</Box>
			</Td>
			<Td minWidth={{ sm: '250px' }} pl="0px" borderBottom="none">
				<Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Text fontSize="md" color={textColor} fontWeight="bold" minWidth="100%">
						{date}
					</Text>
				</Flex>
			</Td>
			<Td borderBottom="none">
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{matchTime}
				</Text>
			</Td>
			<Td borderBottom="none">
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{kills}
				</Text>
			</Td>
			<Td borderBottom="none">
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{deaths}
				</Text>
			</Td>
			<Td borderBottom="none">
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{assists}
				</Text>
			</Td>
			<Td borderBottom="none">
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{balance}
				</Text>
			</Td>
		</Tr>
	);
};
