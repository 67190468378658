import { colors } from '@/theme/colors';

export const VerticalScrollbarStyles = {
	'&::-webkit-scrollbar': {
		width: '4px',
	},
	'&::-webkit-scrollbar-track': {
		width: '6px',
	},
	'&::-webkit-scrollbar-thumb': {
		background: `${colors.bgMain}`,
		borderRadius: '24px',
	},
};

export const CustomHorizontalScrollStyles = {
	'&::-webkit-scrollbar': {
		height: '6px',
	},
	'&::-webkit-scrollbar-track': {
		height: '4px',
	},
	'&::-webkit-scrollbar-thumb': {
		background: `${colors.bgMain}`,
		borderRadius: '4px',
	},
};
