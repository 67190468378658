import { Button, ButtonProps, Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

interface IModalFooterButtons {
	containerProps: FlexProps;
	hideCancelButton?: boolean;
	hideSuccessButton?: boolean;
	cancelTitle?: string;
	successTitle?: string;
	cancelButtonProps?: ButtonProps;
	successButtonProps?: ButtonProps;
}

export const ModalFooterButtons: React.FC<IModalFooterButtons> = ({
	hideCancelButton,
	hideSuccessButton,
	containerProps,
	cancelButtonProps,
	successButtonProps,
	cancelTitle = 'Cancel',
	successTitle = 'Confirm',
}) => {
	return (
		<Flex columnGap="16px" {...containerProps}>
			{!hideCancelButton ? (
				<Button variant="border" w="full" {...cancelButtonProps}>
					<Text>{cancelTitle}</Text>
				</Button>
			) : null}
			{!hideSuccessButton ? (
				<Button variant="filled" w="full" {...successButtonProps}>
					<Text>{successTitle}</Text>
				</Button>
			) : null}
		</Flex>
	);
};
