import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Box, Link, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BaseIconType } from '@/types';
import { colors } from '@/theme/colors';

interface INavItem {
	name: string;
	Icon: React.FC<BaseIconType>;
	ActiveIcon: React.FC<BaseIconType>;
	path: string;
	location: string;
	onlyIcon?: boolean;
}

export const NavItem: React.FC<INavItem> = ({ name, Icon, ActiveIcon, path, location, onlyIcon }) => {
	const active = path === location;
	const activeColor = active ? colors.white[100] : colors.primary;
	return (
		<Box position="relative">
			{active ? (
				<Box display={{ base: onlyIcon ? 'block' : 'none', lg: 'block' }}>
					<motion.div
						style={{
							position: 'absolute',
							top: 0,
							left: onlyIcon ? -26 : -56,
							bottom: 0,
							width: '16px',
							height: '40px',
							borderRadius: '5px',
							background: colors.primary,
						}}
						initial={{ scaleY: 0 }}
						animate={{ scaleY: 1 }}
						transition={{ duration: 0.3 }}
					/>
				</Box>
			) : null}
			<Link
				display="inline-block"
				as={RouterLink}
				transition=".3s"
				to={path}
				cursor={'pointer'}
				_hover={{
					opacity: 1,
				}}
			>
				<Flex justifyContent="flex-start" align="center" py="8px" columnGap="16px">
					{active ? <ActiveIcon /> : <Icon />}
					{!onlyIcon ? <Text color={activeColor}>{name}</Text> : null}
				</Flex>
			</Link>
		</Box>
	);
};
