import React from 'react';
import { Box, Flex, FormLabel, Input, Text } from '@chakra-ui/react';
import { BotIcon, UserIcon } from '@/assets/icons';
import { colors } from '@/theme/colors';

interface CustomSwitchProps {
	isChecked: boolean;
	onToggle: () => void;
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({ isChecked, onToggle }) => {
	return (
		<Flex flexDirection={'column'} alignItems={'center'}>
			<Text mb={2} mr={2}>
				All Bots
			</Text>
			<Flex alignItems="center" gap={'10px'}>
				<Text color={isChecked ? 'primary' : 'gray.500'}>Active</Text>
				<FormLabel
					htmlFor="theme-switcher"
					as={'label'}
					display="flex"
					alignItems="center"
					justifyContent="center"
					gap={2}
					position="relative"
					cursor="pointer"
					m={'0'}
				>
					<Input
						id="theme-switcher"
						type="checkbox"
						checked={isChecked}
						onChange={onToggle}
						display="inline-block"
						appearance="none"
						height="30px"
						width="50px"
						backgroundColor="gray[200]"
						border="1px solid"
						borderColor={isChecked ? 'primary' : 'gray.500'}
						borderRadius="full"
						cursor="pointer"
					/>
					<Box
						className={'iconMove'}
						transition="all 0.2s ease-in"
						transform={`${isChecked ? 'translateX(0)' : 'translateX(20px)'}`}
						position="absolute"
						top={'5px'}
						left={'5px'}
						w={'20px'}
						h={'20px'}
						bg="blue.900"
						borderRadius="full"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{isChecked ? <BotIcon /> : <UserIcon fill={colors.gray[300]} />}
					</Box>
				</FormLabel>
				<Text color={!isChecked ? 'primary' : colors.gray[300]}>Inactive</Text>
			</Flex>
		</Flex>
	);
};
