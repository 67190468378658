import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDisclosure, Drawer, DrawerContent } from '@chakra-ui/react';
import { MobileNav, SidebarContent } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '@/store/reducers/auth.reducer';
import { RootStore } from '@/store';

export const SideBar: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const location = useLocation();
	const dispatch = useDispatch();

	const { loading } = useSelector((state: RootStore) => state.auth);

	const onLogout = () => dispatch(logoutRequest());

	useEffect(() => {
		onClose();
	}, [location.pathname]);

	return (
		<>
			<SidebarContent onClose={onClose} display={{ base: 'none', md: 'none', lg: 'flex' }} onLogout={onLogout} logoutLoading={loading} />
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full"
			>
				<DrawerContent>
					<SidebarContent onClose={onClose} onLogout={onLogout} logoutLoading={loading} onlyIcon />
				</DrawerContent>
			</Drawer>
			<MobileNav
				display={{ base: 'flex', md: 'flex', lg: 'none' }}
				onOpen={onOpen}
				pathname={location.pathname}
				onLogout={onLogout}
				logoutLoading={loading}
			/>
		</>
	);
};
