import { NearestWithdrawalStatusId } from '@/enums';
import { colors } from '@/theme/colors';

export const getNearestWithdrawalStatusProps = (status?: NearestWithdrawalStatusId | null) => {
	switch (status) {
		case NearestWithdrawalStatusId.AVAILABLE:
			return { title: 'Available', color: colors.green[600] };
		case NearestWithdrawalStatusId.CANCELLED:
			return { title: 'Canceled', color: colors.blue[100] };
		case NearestWithdrawalStatusId.CLOSED:
			return { title: 'Closed', color: colors.green[600] };
		default:
			return { title: 'Unknown', color: colors.white[100] };
	}
};
