import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const CloseIcon: React.FC<BaseIconType> = ({ width = '16px', height = '16px', stroke = `${colors.black[100]}` }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
		<path d="M12 4L4 12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M4 4L12 12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
