import React from 'react';
import { BaseIconType } from '@/types';
import { colors } from '@/theme/colors';

export const BotIcon: React.FC<BaseIconType> = ({ width = '26px', height = '26px', fill = `${colors.primary}` }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
				<rect x="5" y="7" width="14" height="10" rx="2" ry="2" />
				<path d="M9 17v2h6v-2" />
				<circle cx="9" cy="10" r="1" />
				<circle cx="15" cy="10" r="1" />
				<path d="M8 14h8" />
				<path d="M12 4v3" />
				<path d="M10 4h4" />
			</g>
		</svg>
	);
};
