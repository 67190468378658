import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '@/store';
import { signInResolver } from '@/validation/sign-in.resolver';
import { signInRequest } from '@/store/reducers/auth.reducer';
import { InputText } from '@/components';
import { Button, Link, Text } from '@chakra-ui/react';

interface SignInPayload {
	login: string;
	password: string;
}
export const LoginForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SignInPayload>({
		resolver: signInResolver,
	});
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootStore) => state.auth);
	const onSubmit = (data: SignInPayload) => {
		dispatch(signInRequest(data));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputText
				{...register('login')}
				borderRadius={'12px'}
				border={'1px solid'}
				borderColor={'#ffffff29'}
				bg={'inherit'}
				minH={'48px'}
				fontSize={'14px'}
				p={'0 16px'}
				_hover={{}}
				_focusVisible={{}}
				label="Login"
				placeholder="Your login"
				errorMsg={errors.login?.message}
			/>
			<br />
			<InputText
				{...register('password')}
				label="Password"
				borderRadius={'12px'}
				placeholder="Your password"
				border={'1px solid'}
				borderColor={'#ffffff29'}
				minH={'48px'}
				fontSize={'14px'}
				p={'0 16px'}
				bg={'inherit'}
				_hover={{}}
				_focusVisible={{}}
				type="password"
				errorMsg={errors.password?.message}
			/>
			<br />
			<Link href={'/forgot-password'}>
				<Text
					transition={'all .2s'}
					_hover={{
						textDecoration: 'underline',
					}}
					ms="4px"
					color={'white'}
					fontWeight="bold"
					fontSize="14px"
				>
					Forgot Your Password?
				</Text>
			</Link>

			<Button
				isLoading={loading}
				fontSize="10px"
				type="submit"
				bg="teal.300"
				w="100%"
				h="45"
				borderRadius={'15px'}
				mb="20px"
				color="white"
				mt="20px"
				_hover={{
					bg: 'teal.200',
				}}
				_active={{
					bg: 'teal.400',
				}}
			>
				SIGN IN
			</Button>
		</form>
	);
};
