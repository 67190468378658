type NormalizePriceOptions = {
	digits?: number;
	locale?: string;
	localeOptions?: Intl.NumberFormatOptions;
};

type NormalizePrice = (price?: number | string, options?: NormalizePriceOptions) => string;

const defaultOptions: NormalizePriceOptions = {
	digits: 2,
	locale: 'en-US',
	localeOptions: {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	},
};

export const normalizePrice: NormalizePrice = (price, options) => {
	const { digits, locale, localeOptions } = options ? options : defaultOptions;

	return ((typeof price !== 'number' || isNaN(price) ? 0 : +price) / (digits ? 10 ** digits : 1)).toLocaleString(locale, {
		...defaultOptions.localeOptions,
		...localeOptions,
	});
};
