import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const CommissionsIcon: React.FC<BaseIconType> = ({
	width = '24px',
	height = '24px',
	fill = `${colors.primary}`,
	stroke = `${colors.primary}`,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.30664 16.3818C5.64501 16.8987 6.04378 17.3772 6.49667 17.8065C7.61886 18.8701 9.02523 19.5858 10.5456 19.8668C12.066 20.1479 13.6353 19.9824 15.0636 19.3903C16.4919 18.7982 17.718 17.8049 18.5936 16.5305C19.4692 15.2562 19.9567 13.7554 19.9972 12.2098C20.0378 10.6641 19.6295 9.13989 18.8219 7.82142C18.0143 6.50295 16.8419 5.44677 15.4466 4.78065C14.5023 4.32984 13.4832 4.07076 12.4479 4.0127L13.0031 6.08459C13.5482 6.17703 14.0808 6.34484 14.5849 6.58552C15.6314 7.08511 16.5107 7.87725 17.1164 8.8661C17.7221 9.85496 18.0283 10.9981 17.9979 12.1574C17.9675 13.3166 17.6019 14.4422 16.9452 15.3979C16.2885 16.3537 15.3689 17.0987 14.2977 17.5428C13.2265 17.9868 12.0495 18.111 10.9092 17.9002C9.76892 17.6894 8.71415 17.1526 7.8725 16.3549C7.69677 16.1883 7.53189 16.0119 7.37854 15.8267L5.30664 16.3818Z"
			fill={fill}
		/>
		<path
			d="M5.91239 4.06647C6.68924 3.47037 7.54796 2.99272 8.46042 2.64739C8.87978 2.48868 9.08946 2.40932 9.28694 2.51053C9.48442 2.61174 9.54649 2.84338 9.67063 3.30667L11.7412 11.0341C11.8632 11.4894 11.9242 11.7171 11.8206 11.8964C11.7171 12.0758 11.4894 12.1368 11.0341 12.2588L3.30667 14.3294C2.84338 14.4535 2.61174 14.5156 2.42535 14.3952C2.23896 14.2747 2.20284 14.0535 2.13061 13.6109C1.97344 12.6481 1.95774 11.6656 2.08555 10.6947C2.25696 9.39275 2.68314 8.13728 3.33975 7C3.99636 5.86272 4.87054 4.8659 5.91239 4.06647Z"
			stroke={stroke}
			strokeWidth="2"
		/>
	</svg>
);
