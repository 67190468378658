import { Box, Heading, InputLeftElement, Input, Flex, InputGroup, Menu, MenuList, MenuButton, Button, MenuItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '@/theme/colors';
import { InfinitePaginationPayload } from '@/types';
import { UsersTableData } from '@/pages/Users/components';
import { getUsersRequest, resetUsersState } from '@/pages/Users/store/reducer';
import { ChevronDownIcon, SearchIcon } from '@/assets/icons';
import { SearchType } from '@/pages/Users/types';
import { useDebounce } from '@/hooks';
import { MatchResultsModal } from '@/pages/Users/components/MatchResultsModal/MatchResultsModal';

const Users: React.FC = () => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [isOpen, setIsOpen] = useState<number | null>(null);
	const [selectedItem, setSelectedItem] = useState(SearchType.Name);
	const searchParam = useDebounce(searchTerm, 500);
	const [hasLoaded, setHasLoaded] = useState(false);

	const onGetUsersTable = (payload: InfinitePaginationPayload) => dispatch(getUsersRequest(payload));
	const onResetUsersTable = () => dispatch(resetUsersState());

	const handleSelectItemChange = (filter: SearchType) => {
		setSelectedItem(filter);
		setSearchTerm('');
		setPage(1);
	};

	const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		setPage(1);
	};

	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	useEffect(() => {
		if (page !== 1) {
			onGetUsersTable({
				currentPage: page,
				pageSize: 10,
				nameSearch: selectedItem === SearchType.Name && selectedItem ? searchTerm : '',
				emailSearch: selectedItem === SearchType.Email && selectedItem ? searchTerm : '',
				getMore: true,
			});
		}
	}, [page]);

	useEffect(() => {
		if (hasLoaded) {
			onGetUsersTable({
				currentPage: 1,
				pageSize: 10,
				nameSearch: selectedItem === SearchType.Name && selectedItem ? searchTerm : '',
				emailSearch: selectedItem === SearchType.Email && selectedItem ? searchTerm : '',
				getMore: false,
			});
			return () => {
				onResetUsersTable();
			};
		} else {
			setHasLoaded(true);
		}
	}, [searchParam]);

	useEffect(() => {
		if (searchTerm) {
			onGetUsersTable({
				currentPage: 1,
				pageSize: 10,
				nameSearch: selectedItem === SearchType.Name && selectedItem ? searchTerm : '',
				emailSearch: selectedItem === SearchType.Email && selectedItem ? searchTerm : '',
				getMore: false,
			});
			return () => {
				onResetUsersTable();
			};
		}
	}, [selectedItem]);

	useEffect(() => {
		onGetUsersTable({
			currentPage: 1,
			pageSize: 10,
			nameSearch: '',
			emailSearch: '',
			getMore: false,
		});
	}, []);

	const onMatchResultsModalOpen = (userId: number) => {
		setIsOpen(userId);
	};

	return (
		<Box pt={{ base: '30px', lg: '50px' }} pb="30px">
			<MatchResultsModal isOpen={Boolean(isOpen)} onClose={() => setIsOpen(null)} userId={isOpen} />
			<Heading variant="h2">Users</Heading>
			<Flex gap={'30px'} marginTop={'32px'}>
				<InputGroup maxW={'300px'}>
					<InputLeftElement pointerEvents="none">
						<SearchIcon />
					</InputLeftElement>
					<Input
						value={searchTerm}
						onChange={handleSearchTermChange}
						variant="outline"
						placeholder="Search"
						_focusVisible={{
							borderColor: colors.primary,
						}}
					/>
				</InputGroup>
				<Menu>
					<MenuButton
						minW={'150px'}
						as={Button}
						variant="outline"
						rightIcon={<ChevronDownIcon />}
						color={'white'}
						_focus={{ borderColor: colors.primary }}
						_hover={{ bg: colors.gray[200] }}
					>
						{selectedItem}
					</MenuButton>
					<MenuList minW={'150px'} bg={colors.green[300]}>
						<MenuItem onClick={() => handleSelectItemChange(SearchType.Name)} bg={colors.green[300]} _hover={{ bg: colors.gray[200] }}>
							By name
						</MenuItem>
						<MenuItem onClick={() => handleSelectItemChange(SearchType.Email)} bg={colors.green[300]} _hover={{ bg: colors.gray[200] }}>
							By email
						</MenuItem>
					</MenuList>
				</Menu>
			</Flex>

			<Box overflow="hidden" h="auto">
				<Box
					w="100%"
					mt="32px"
					p="16px 16px 12px 16px"
					borderRadius="10px"
					bg={colors.green[300]}
					maxH="calc(100vh - 170px)"
					overflowX="hidden"
					border={`1px solid ${colors.green[200]}`}
				>
					<UsersTableData page={page} onPageChange={onPageChange} onMatchResultsModalOpen={onMatchResultsModalOpen} />
				</Box>
			</Box>
		</Box>
	);
};

export default Users;
