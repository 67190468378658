import React from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { SideBar } from './SideBar/Sidebar';

export const DashboardLayout: React.FC = () => {
	return (
		<Box minH="100vh">
			<SideBar />
			<Box
				ml={{ base: '80px', lg: '310px' }}
				transition=".all .3s"
				w={{
					base: 'calc(100% - 80px)',
					lg: 'calc(100% - 325px)',
				}}
				p="4"
			>
				<Outlet />
			</Box>
		</Box>
	);
};
