import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfinitePaginationPayload, InfinitePaginationResponse, PaginationPayload, PayloadError } from '@/types';
import {
	UserDetailsPayload,
	UserNearestWithdrawalInfo,
	UserRefillsTableResponse,
	UserTableDetailsItem,
	UserWithdrawalsTableResponse,
	WithdrawalsManagementResponse,
	WithdrawManagementItem,
} from '../types';

interface InitialState {
	withdrawManagement: {
		data: WithdrawManagementItem[];
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};
	userRefills: {
		data: UserTableDetailsItem[];
		sumAmount: number;
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};
	userWithdrawals: {
		data: UserTableDetailsItem[];
		sumAmount: number;
		nearestWithdrawal: UserNearestWithdrawalInfo | null;
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};
	updateWithdrawalStatus: {
		loading: boolean;
		success: boolean;
		errors: PayloadError[];
	};
}

const initialState: InitialState = {
	withdrawManagement: {
		data: [],
		totalCount: 0,
		loading: false,
		errors: [],
	},
	userRefills: {
		data: [],
		sumAmount: 0,
		totalCount: 0,
		loading: false,
		errors: [],
	},
	userWithdrawals: {
		data: [],
		sumAmount: 0,
		nearestWithdrawal: null,
		totalCount: 0,
		loading: false,
		errors: [],
	},
	updateWithdrawalStatus: {
		loading: false,
		success: false,
		errors: [],
	},
};

const withdrawManagementReducer = createSlice({
	name: 'withdrawManagement',
	initialState,
	reducers: {
		getWithdrawManagementRequest: (state, _action: PayloadAction<InfinitePaginationPayload>) => {
			state.withdrawManagement.loading = true;
			state.withdrawManagement.errors = [];
		},
		getWithdrawManagementSuccess: (state, action: PayloadAction<InfinitePaginationResponse<WithdrawalsManagementResponse>>) => {
			const { getMore, data } = action.payload;
			const { count, users } = data;
			if (getMore) {
				state.withdrawManagement.data = [...state.withdrawManagement.data, ...users];
			} else {
				state.withdrawManagement.data = users;
			}
			state.withdrawManagement.totalCount = count;
			state.withdrawManagement.loading = false;
		},
		getWithdrawManagementErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.withdrawManagement.loading = false;
			state.withdrawManagement.errors = action.payload;
		},

		resetWithdrawManagementState: state => {
			return {
				...state,
				withdrawManagement: { ...initialState.withdrawManagement },
			};
		},

		getUserRefillsRequest: (state, _action: PayloadAction<UserDetailsPayload>) => {
			state.userRefills.loading = true;
			state.userRefills.errors = [];
		},
		getUserRefillsSuccess: (state, action: PayloadAction<InfinitePaginationResponse<UserRefillsTableResponse>>) => {
			const { data, getMore } = action.payload;
			const { totalCount, financialInfo, sumAmount } = data;
			if (getMore) {
				state.userRefills.data = [...state.userRefills.data, ...financialInfo];
			} else {
				state.userRefills.data = financialInfo;
			}
			state.userRefills.totalCount = totalCount;
			state.userRefills.sumAmount = sumAmount;
			state.userRefills.loading = false;
		},
		getUserRefillsErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.userRefills.loading = false;
			state.userRefills.errors = action.payload;
		},

		resetUserRefillsState: state => {
			return {
				...state,
				userRefills: { ...initialState.userRefills },
			};
		},

		getUserWithdrawalsRequest: (state, _action: PayloadAction<UserDetailsPayload>) => {
			state.userWithdrawals.loading = true;
			state.userWithdrawals.errors = [];
		},
		getUserWithdrawalsSuccess: (state, action: PayloadAction<InfinitePaginationResponse<UserWithdrawalsTableResponse>>) => {
			const { data, getMore } = action.payload;
			const { userId, totalCount, financialInfo, sumAmount, nearestWithdrawal } = data;
			if (getMore) {
				state.userWithdrawals.data = [...state.userWithdrawals.data, ...financialInfo];
			} else {
				state.userWithdrawals.data = financialInfo;
			}

			if (state.withdrawManagement.data && nearestWithdrawal?.withdrawalStatus) {
				const itemIndex = state.withdrawManagement.data.findIndex(item => {
					return userId === item.userId;
				});

				const withdrawalStatusPayload = {
					id: nearestWithdrawal.withdrawalStatus.id,
					name: nearestWithdrawal.withdrawalStatus.name,
				};

				state.withdrawManagement.data[itemIndex].withdrawalStatus = withdrawalStatusPayload;
			}
			state.userWithdrawals.totalCount = totalCount;
			state.userWithdrawals.sumAmount = sumAmount;
			state.userWithdrawals.nearestWithdrawal = nearestWithdrawal;
			state.userWithdrawals.loading = false;
		},
		getUserWithdrawalsErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.userWithdrawals.loading = false;
			state.userWithdrawals.errors = action.payload;
		},

		resetUserWithdrawalsState: state => {
			return {
				...state,
				userWithdrawals: { ...initialState.userWithdrawals },
			};
		},

		updateWithdrawalStatusRequest: (state, _action: PayloadAction<{ userId: number }>) => {
			state.updateWithdrawalStatus.loading = true;
			state.updateWithdrawalStatus.errors = [];
		},
		updateWithdrawalStatusSuccess: (state, _action: PayloadAction<unknown>) => {
			state.updateWithdrawalStatus.loading = false;
			state.updateWithdrawalStatus.success = true;
		},
		updateWithdrawalStatusErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.updateWithdrawalStatus.loading = false;
			state.updateWithdrawalStatus.errors = action.payload;
		},

		resetUpdateWithdrawalStatusState: (state, action: PayloadAction<{ userId: number }>) => {
			const { userId } = action.payload;
			const withdrawalStatusPayload = { id: 2, name: 'Canceled' };

			if (state.userWithdrawals.nearestWithdrawal) {
				state.userWithdrawals.nearestWithdrawal = {
					...state.userWithdrawals.nearestWithdrawal,
					withdrawalStatus: withdrawalStatusPayload,
				};
			}

			if (state.withdrawManagement.data) {
				const itemIndex = state.withdrawManagement.data.findIndex(item => {
					return userId === item.userId;
				});

				state.withdrawManagement.data[itemIndex].withdrawalStatus = withdrawalStatusPayload;
			}

			state.updateWithdrawalStatus.success = false;
		},
	},
});

export const {
	getWithdrawManagementRequest,
	getWithdrawManagementSuccess,
	getWithdrawManagementErrors,
	resetWithdrawManagementState,

	getUserRefillsRequest,
	getUserRefillsSuccess,
	getUserRefillsErrors,
	resetUserRefillsState,

	getUserWithdrawalsRequest,
	getUserWithdrawalsSuccess,
	getUserWithdrawalsErrors,
	resetUserWithdrawalsState,

	updateWithdrawalStatusRequest,
	updateWithdrawalStatusSuccess,
	updateWithdrawalStatusErrors,
	resetUpdateWithdrawalStatusState,
} = withdrawManagementReducer.actions;

export default withdrawManagementReducer.reducer;
