import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const WithdrawalsActiveIcon: React.FC<BaseIconType> = ({
	width = '24px',
	height = '24px',
	fill = `${colors.white[100]}`,
	stroke = `${colors.white[100]}`,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.5794 6.155L19.5553 8.53965C20.3338 8.39963 21.166 8.63049 21.7678 9.23223C21.8361 9.3006 21.8997 9.37195 21.9585 9.44585C21.8852 8.21923 21.6824 7.43977 21.1213 6.87868C20.7354 6.49279 20.2463 6.27637 19.5794 6.155ZM14.5807 6L14.5548 8.562C13.7454 8.37862 12.8624 8.60203 12.2322 9.23223C11.7441 9.72039 11.5 10.3602 11.5 11H2.00174C2.01384 8.82497 2.1103 7.64706 2.87868 6.87868C3.75736 6 5.17157 6 8 6H14.5807ZM12.4645 13H2V15C2 17.8284 2 19.2426 2.87868 20.1213C3.75736 21 5.17157 21 8 21H16C18.8284 21 20.2426 21 21.1213 20.1213C22 19.2426 22 17.8284 22 15V13H21.5355L18.8738 15.6617C18.8559 15.6797 18.8377 15.6974 18.8193 15.7147C18.3576 16.2043 17.7007 16.5072 16.9747 16.4999C16.267 16.4927 15.6309 16.1921 15.1808 15.7148C15.1624 15.6974 15.1442 15.6797 15.1262 15.6617L12.4645 13ZM6 17C6 16.4477 6.44772 16 7 16H7.01C7.56228 16 8.01 16.4477 8.01 17C8.01 17.5523 7.56228 18 7.01 18H7C6.44772 18 6 17.5523 6 17Z"
			fill={fill}
		/>
		<path
			d="M17 4V14M20 11L17.1061 13.8939C17.0475 13.9525 16.9525 13.9525 16.8939 13.8939L14 11"
			stroke={stroke}
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);
