import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const SearchIcon: React.FC<BaseIconType> = ({ width = '24px', height = '24px', fill = `${colors.white[100]}` }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
		<circle cx="11" cy="11" r="7" stroke={fill} strokeWidth="2" />
		<line x1="16.7071" y1="16.2929" x2="20.7071" y2="20.2929" stroke={fill} strokeWidth="2" strokeLinecap="round" />
	</svg>
);
