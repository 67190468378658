import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { RootStore } from '@/store';
import { useSelector } from 'react-redux';

interface ProtectedRouteProps {
	children: ReactNode;
}
export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
	const accessToken = useSelector((state: RootStore) => state.auth.token.accessToken);

	if (!accessToken) {
		return <Navigate to="/sign-in" replace={true} />;
	}

	return children;
};
