import { all } from 'redux-saga/effects';
import watchAuthSaga from './auth-sagas';
import watchCommissionPageSaga from '@/pages/Commissions/store/saga';
import watchWithdrawManagementPageSaga from '@/pages/WithdrawManagement/store/saga';
import watchResetPasswordEmail from '@/store/sagas/auth-sagas/reset-password-email.saga';
import watchResetPasswordSaga from '@/store/sagas/auth-sagas/reset-password.saga';
import watchLogoutSaga from '@/store/sagas/auth-sagas/logout.saga';
import watchUsersPageSaga from '@/pages/Users/store/saga';

export default function* rootSaga() {
	yield all([
		watchAuthSaga(),
		watchCommissionPageSaga(),
		watchWithdrawManagementPageSaga(),
		watchResetPasswordEmail(),
		watchResetPasswordSaga(),
		watchLogoutSaga(),
		watchUsersPageSaga(),
	]);
}
