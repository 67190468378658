import axios from 'axios';
import { store } from '@/store';
import { logoutSuccess, refreshToken } from '@/store/reducers/auth.reducer';
import { Alerter, setAuthHeaderToken } from '@/utils';

export default function intercept(): void {
	axios.defaults.baseURL = import.meta.env.VITE_BASE_URL || 'http://localhost:3000';
	let isRefreshing = false;
	const subscribers: Array<(value: string) => void> = [];

	axios.interceptors.response.use(undefined, err => {
		const {
			config,
			response: { status },
		} = err;
		const originalRequest = config;
		if (status === 401) {
			if (!isRefreshing) {
				isRefreshing = true;
				axios
					.post('/api/v1/auth/refresh-token', {
						accessToken: store.getState().auth.token.accessToken,
						refreshToken: store.getState().auth.token.refreshToken,
					})
					.then(res => {
						isRefreshing = false;
						onRefreshed(res.data.value.accessToken);
						store.dispatch(refreshToken(res.data.value));
						setAuthHeaderToken(res.data.value.accessToken);
					})
					.catch(() => {
						Alerter.error('You need login again to continue');
						store.dispatch(logoutSuccess());
					});
			}
			return new Promise(resolve => {
				subscribeTokenRefresh((token: string) => {
					originalRequest.headers.Authorization = `Bearer ${token}`;
					resolve(axios(originalRequest));
				});
			});
		}

		return Promise.reject(err);
	});

	function subscribeTokenRefresh(cb: (value: string) => void) {
		subscribers.push(cb);
	}

	function onRefreshed(token: string) {
		subscribers.map(cb => cb(token));
	}
}
