import React from 'react';
import { CommissionsActiveIcon, CommissionsIcon, UserActiveIcon, UserIcon, WithdrawalsActiveIcon, WithdrawalsIcon } from '@/assets/icons';
import { BaseIconType } from '@/types';

interface NavLinkItem {
	id: number;
	name: string;
	path: string;
	Icon: React.FC<BaseIconType>;
	ActiveIcon: React.FC<BaseIconType>;
}

export const NavLinkItems: NavLinkItem[] = [
	{
		id: 1,
		name: 'Commission',
		path: '/',
		Icon: CommissionsIcon,
		ActiveIcon: CommissionsActiveIcon,
	},
	{
		id: 2,
		name: 'Withdraw Management',
		path: '/withdraw-management',
		Icon: WithdrawalsIcon,
		ActiveIcon: WithdrawalsActiveIcon,
	},
	{
		id: 3,
		name: 'Users',
		path: '/users',
		Icon: UserIcon,
		ActiveIcon: UserActiveIcon,
	},
];
