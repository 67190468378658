import { ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from '../colors';

export const Button: ComponentStyleConfig = {
	variants: {
		border: {
			bg: 'transparent',
			border: `1px solid ${colors.primary}`,
			borderRadius: '10px',
			height: '40px',
			transition: 'opacity 0.3s ease',
			_hover: {
				opacity: 0.75,
				_disabled: {
					opacity: 0.4,
				},
			},
			_loading: {
				color: 'white',
				_hover: {
					opacity: 0.75,
				},
			},
			_active: {},
		},
		filled: {
			bg: colors.primary,
			border: `1px solid ${colors.primary}`,
			p: '8px 16px',
			height: '40px',
			borderRadius: '10px',
			transition: 'opacity 0.3s ease',
			_hover: {
				opacity: 0.75,
				_disabled: {
					bg: colors.primary,
					opacity: 0.4,
				},
			},
			_loading: {
				_hover: {
					opacity: 0.75,
				},
			},
			_active: {},
			'> p': {
				color: colors.bgMain,
			},
		},
	},
};
