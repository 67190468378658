import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { HistoryItem, Loader } from '@/components';
import { colors } from '@/theme/colors';
import { normalizePrice, toDisplayedDate } from '@/utils';
import { RootStore } from '@/store';
import { UserDetailsPayload } from '../types';
import { getNearestWithdrawalStatusProps } from '../utils';
import { NearestWithdrawalStatusId } from '@/enums';

const pageSize = 10;

interface IWithdrawalsTab {
	onGetWithdrawalsData: (payload: UserDetailsPayload) => void;
	onOpenChangeStatusModal: () => void;
	onResetWithdrawalsUserData: () => void;
	userId: number;
}

export const WithdrawalsTab: React.FC<IWithdrawalsTab> = ({
	onGetWithdrawalsData,
	userId,
	onResetWithdrawalsUserData,
	onOpenChangeStatusModal,
}) => {
	const [page, setPage] = useState(1);

	const { data, totalCount, sumAmount, nearestWithdrawal, loading } = useSelector(
		(state: RootStore) => state.withdrawManagement.userWithdrawals,
	);

	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	const hasMore = useMemo(() => data.length < totalCount, [data.length, totalCount]);

	useEffect(() => {
		onGetWithdrawalsData({
			currentPage: 1,
			getMore: false,
			userId,
			pageSize,
		});
		setPage(1);

		return () => {
			onResetWithdrawalsUserData();
		};
	}, []);
	useEffect(() => {
		if (page !== 1) {
			onGetWithdrawalsData({
				currentPage: page,
				getMore: true,
				userId,
				pageSize,
			});
		}
	}, [page]);

	const { createdDate, id, withdrawalStatus } = nearestWithdrawal || {};
	const formattedNearestDate = toDisplayedDate(createdDate);
	const currentWithdrawalStatus = getNearestWithdrawalStatusProps(withdrawalStatus?.id);

	const initialDataLoading = !data.length && loading;

	const disabledChangeNearestWithdrawalStatus = withdrawalStatus?.id !== NearestWithdrawalStatusId.AVAILABLE;
	return (
		<Box>
			<VStack alignItems="flex-start" spacing="16px">
				<Text>Sum of withdrawed credits:</Text>
				{initialDataLoading ? (
					<Skeleton h="40px" width="150px" borderRadius="10px" startColor={colors.bgMain} endColor={colors.blue[100]} />
				) : (
					<Text fontSize="32px" lineHeight="40px" fontWeight={700} color={colors.blue[100]}>
						{normalizePrice(sumAmount)}
					</Text>
				)}
			</VStack>
			{initialDataLoading ? (
				<Flex mt="24px" justify="space-between" align="center">
					<Box>
						<Skeleton h="55px" width="160px" borderRadius="10px" />
					</Box>
					<Flex columnGap="32px">
						<Box>
							<Skeleton h="55px" width="170px" borderRadius="10px" />
						</Box>
						<Box>
							<Skeleton h="40px" width="125px" borderRadius="10px" />
						</Box>
					</Flex>
				</Flex>
			) : nearestWithdrawal ? (
				<Flex mt="24px" justify="space-between" align="center">
					<VStack spacing="16px" alignItems="flex-start">
						<Text color={colors.primary}>Date of nearest withdraw:</Text>
						<Text variant="bodyLarge">{formattedNearestDate}</Text>
					</VStack>

					<Flex columnGap="32px">
						<VStack spacing="16px" alignItems="flex-start">
							<Text color={colors.primary}>Nearest withdrawal status:</Text>
							<Text variant="bodyLarge" color={currentWithdrawalStatus.color}>
								{currentWithdrawalStatus.title}
							</Text>
						</VStack>
						<Button variant="border" isDisabled={disabledChangeNearestWithdrawalStatus} onClick={onOpenChangeStatusModal}>
							<Text>Change Status</Text>
						</Button>
					</Flex>
				</Flex>
			) : null}

			<InfiniteScroll
				dataLength={data.length}
				next={() => {
					if (!loading) {
						onPageChange();
					}
				}}
				hasMore={hasMore}
				loader={
					page !== 1 &&
					loading && (
						<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
							<Loader centerHeight="40px" spinnerSize="sm" />
						</Flex>
					)
				}
				height="auto"
				className="infinite-scroll-section"
				style={{
					maxHeight: '230px',
					marginTop: '20px',
					paddingRight: data.length > 3 ? '4px' : '0px',
				}}
			>
				{loading && page === 1 ? (
					<Flex h="226px" mt="4px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
						<Loader centerHeight="50px" spinnerSize="md" />
					</Flex>
				) : (
					<>
						{data.map(item => (
							<HistoryItem
								key={item.id}
								date={item.date}
								valueBefore={normalizePrice(item.balanceBefore)}
								valueAfter={`${normalizePrice(item.actionAmount)}`}
								titleBefore="Balance before"
								titleAfter="Withdrawal"
								balanceAfterValue={normalizePrice(item.balanceAfter)}
								exchangeColor={colors.blue[100]}
								containerProps={{
									mt: '4px',
								}}
							/>
						))}
					</>
				)}
			</InfiniteScroll>
		</Box>
	);
};
