import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { HistoryItem, Loader } from '@/components';
import { EditIcon } from '@/assets/icons';
import { DataCard } from './DataCard';
import { RootStore } from '@/store';
import { colors } from '@/theme/colors';
import { InfinitePaginationPayload } from '@/types';
import { getCommissionByType } from '../utils';

const pageSize = 10;

interface ICommissionSection {
	onOpenEditCommissionModal: () => void;
	onGetCommissionData: (payload: InfinitePaginationPayload) => void;
	onResetCommissionData: () => void;
}

export const CommissionSection: React.FC<ICommissionSection> = ({
	onGetCommissionData,
	onResetCommissionData,
	onOpenEditCommissionModal,
}) => {
	const [page, setPage] = useState(1);

	const { data, totalCount, currentCommission, loading } = useSelector((state: RootStore) => state.commission.commissionInfo);
	const { success } = useSelector((state: RootStore) => state.commission.updateCommission);

	const { value, commissionTypeId } = currentCommission || {};

	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	const hasMore = useMemo(() => data.length < totalCount, [data.length, totalCount]);

	useEffect(() => {
		if (!success) {
			onGetCommissionData({
				currentPage: 1,
				getMore: false,
				pageSize,
			});
			setPage(1);
		}

		return () => {
			onResetCommissionData();
		};
	}, [success]);
	useEffect(() => {
		if (page !== 1) {
			onGetCommissionData({
				currentPage: page,
				getMore: true,
				pageSize,
			});
		}
	}, [page]);

	const currentValue = getCommissionByType(commissionTypeId, value);
	const initialDataLoading = !data.length && loading;

	return (
		<Box>
			<Heading variant="h2" mb="32px">
				Commissions
			</Heading>
			<DataCard
				onOpenModal={onOpenEditCommissionModal}
				cardTitle="Current Commission"
				historyTitle="Commission Change"
				cardValue={currentValue}
				buttonText="Edit"
				buttonIcon={<EditIcon />}
				currentValueLoading={initialDataLoading}
				containerProps={{
					maxW: '404px',
				}}
			>
				<InfiniteScroll
					dataLength={data.length}
					next={() => {
						if (!loading) {
							onPageChange();
						}
					}}
					hasMore={hasMore}
					loader={
						page !== 1 &&
						loading && (
							<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
								<Loader centerHeight="40px" spinnerSize="sm" />
							</Flex>
						)
					}
					height="auto"
					className="infinite-scroll-section"
					style={{
						maxHeight: '230px',
						paddingRight: data.length > 3 ? '4px' : '0px',
					}}
				>
					{loading && page === 1 ? (
						<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
							<Loader centerHeight="50px" spinnerSize="md" />
						</Flex>
					) : (
						<>
							{data.length ? (
								data.map((item, index) => {
									const formattedValueBefore = getCommissionByType(item.commissionTypeBeforeId, item.valueBefore);
									const formattedValueAfter = getCommissionByType(item.commissionTypeAfterId, item.valueAfter);
									return (
										<HistoryItem
											key={index}
											date={item.createdAt}
											valueBefore={formattedValueBefore}
											valueAfter={formattedValueAfter}
											sectionBeforeProps={{ width: 'auto' }}
											sectionAfterProps={{ width: '44px' }}
											containerProps={{
												mt: index === 0 ? '0px' : '4px',
											}}
										/>
									);
								})
							) : (
								<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
									<Text>No Data</Text>
								</Flex>
							)}
						</>
					)}
				</InfiniteScroll>
			</DataCard>
		</Box>
	);
};
