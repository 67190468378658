export const digitsAndDots = (str: string) => {
	const inputValue = str.replace(/[^\d.]/g, '');
	const firstDotIndex = inputValue.indexOf('.');
	if (firstDotIndex !== -1) {
		const beforeDot = inputValue.substring(0, firstDotIndex + 1);
		const afterDot = inputValue
			.substring(firstDotIndex + 1)
			.replace(/\./g, '')
			.slice(0, 2);
		return beforeDot + afterDot;
	}
	return inputValue;
};

export const onlyDigits = (str: string) => {
	let inputValue = str.replace(/[^\d]/g, '');
	return inputValue;
};
