import React from 'react';
import { BaseIconType } from '@/types';
import { colors } from '@/theme/colors';

export const UserIcon: React.FC<BaseIconType> = ({ width = '24px', height = '24px', fill = `${colors.primary}` }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
				<circle cx="12" cy="7.5" r="3" />
				<path d="M19.5 20.5c-.475-9.333-14.525-9.333-15 0" />
			</g>
		</svg>
	);
};
