import { call, put, takeEvery } from 'redux-saga/effects';
import API from '@/api/api.base';
import { Alerter } from '@/utils';
import { ApiResponse, ApiResponseEmpty, SagaError } from '@/types';
import {
	getCommissionInfoRequest,
	getCommissionInfoSuccess,
	getCommissionInfoErrors,
	getWithdrawalsInfoRequest,
	getWithdrawalsInfoSuccess,
	getWithdrawalsInfoErrors,
	getBalanceRequest,
	getBalanceSuccess,
	getBalanceErrors,
	updateCommissionRequest,
	updateCommissionSuccess,
	updateCommissionErrors,
	updateWithdrawalsRequest,
	updateWithdrawalsSuccess,
	updateWithdrawalsErrors,
} from './reducer';
import { AvailableBalanceResponse, CommissionInfoResponse, WithdrawalInfoResponse } from '../types';

function* workerGetCommissionInfo(action: ReturnType<typeof getCommissionInfoRequest>) {
	const { currentPage, pageSize, getMore } = action.payload;
	try {
		const result: ApiResponse<CommissionInfoResponse> = yield call(
			API.get,
			`/api/v1/admin/commission/commissions?CurrentPage=${currentPage}&PageSize=${pageSize}`,
			{},
		);

		if (result.success) {
			yield put(getCommissionInfoSuccess({ data: result.value, getMore }));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getCommissionInfoErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerGetWithdrawalsInfo(action: ReturnType<typeof getWithdrawalsInfoRequest>) {
	const { currentPage, pageSize, getMore } = action.payload;
	try {
		const result: ApiResponse<WithdrawalInfoResponse> = yield call(API.get, '/api/v1/admin/commission/withdrawals', {
			currentPage,
			pageSize,
		});

		if (result.success) {
			yield put(getWithdrawalsInfoSuccess({ data: result.value, getMore }));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getWithdrawalsInfoErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerGetBalance(action: ReturnType<typeof getBalanceRequest>) {
	try {
		const result: ApiResponse<AvailableBalanceResponse> = yield call(API.get, '/api/v1/admin/commission/balance', {});

		if (result.success) {
			yield put(getBalanceSuccess(result.value));
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(getBalanceErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerUpdateCommission(action: ReturnType<typeof updateCommissionRequest>) {
	try {
		const result: ApiResponseEmpty = yield call(API.post, '/api/v1/admin/commission/commissions', action.payload);

		if (result.success) {
			yield put(updateCommissionSuccess(action.payload));
			Alerter.success(result.messages[0]?.message);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(updateCommissionErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

function* workerUpdateWithdrawals(action: ReturnType<typeof updateWithdrawalsRequest>) {
	const { amount } = action.payload;
	try {
		const result: ApiResponseEmpty = yield call(API.post, `/api/v1/admin/commission/create-withdraw?amount=${amount}`, {});

		if (result.success) {
			yield put(updateWithdrawalsSuccess(action.payload));
			Alerter.success(result.messages[0]?.message);
		} else {
			Alerter.error(result.errors[0]?.message);
			yield put(updateWithdrawalsErrors(result.errors));
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

export default function* watchCommissionPageSaga() {
	yield takeEvery(getCommissionInfoRequest.type, workerGetCommissionInfo);
	yield takeEvery(getWithdrawalsInfoRequest.type, workerGetWithdrawalsInfo);
	yield takeEvery(getBalanceRequest.type, workerGetBalance);
	yield takeEvery(updateCommissionRequest.type, workerUpdateCommission);
	yield takeEvery(updateWithdrawalsRequest.type, workerUpdateWithdrawals);
}
