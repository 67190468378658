import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const EditIcon: React.FC<BaseIconType> = ({ width = '24px', height = '24px', fill = `${colors.black[100]}` }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.204 10.7959L19 8.99994C19.5453 8.45469 19.8179 8.18207 19.9636 7.88797C20.2409 7.32842 20.2409 6.67146 19.9636 6.11191C19.8179 5.81782 19.5453 5.54519 19 4.99994C18.4548 4.45469 18.1821 4.18207 17.888 4.03633C17.3285 3.75905 16.6715 3.75905 16.112 4.03633C15.8179 4.18207 15.5453 4.45469 15 4.99994L13.1814 6.8186C14.1452 8.4692 15.5314 9.84476 17.204 10.7959ZM11.7269 8.27305L4.8564 15.1436C4.43134 15.5686 4.21881 15.7812 4.07907 16.0422C3.93934 16.3033 3.88039 16.5981 3.7625 17.1875L3.1471 20.2645C3.08058 20.5971 3.04732 20.7634 3.14193 20.858C3.23654 20.9526 3.40284 20.9194 3.73545 20.8529L6.81243 20.2375C7.40189 20.1196 7.69661 20.0606 7.95771 19.9209C8.21881 19.7812 8.43134 19.5686 8.8564 19.1436L15.7458 12.2542C14.1241 11.2385 12.7524 9.87622 11.7269 8.27305Z"
			fill={fill}
		/>
	</svg>
);
