import React from 'react';
import {
	Button,
	Flex,
	HStack,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CloseIcon } from '@/assets/icons';
import { colors } from '@/theme/colors';
import { UserDetailsPayload, WithdrawManagementItem } from '../types';
import { TABS } from '../constants';
import { RefillsTab, WithdrawalsTab } from '../tabs';

interface IUserDetailsModal {
	isOpen: boolean;
	onClose: () => void;
	user: WithdrawManagementItem | null;
	onGetRefillsData: (payload: UserDetailsPayload) => void;
	onGetWithdrawalsData: (payload: UserDetailsPayload) => void;
	onOpenChangeStatusModal: () => void;
	onResetRefillsUserData: () => void;
	onResetWithdrawalsUserData: () => void;
}

export const UserDetailsModal: React.FC<IUserDetailsModal> = ({
	isOpen,
	onClose,
	user,
	onGetRefillsData,
	onGetWithdrawalsData,
	onOpenChangeStatusModal,
	onResetRefillsUserData,
	onResetWithdrawalsUserData,
}) => {
	const renderTabs = TABS.map(tab => (
		<Tab
			key={tab.id}
			borderBottom={`2px solid ${colors.bgMain}`}
			_selected={{
				borderBottom: `2px solid ${colors.primary}`,
				p: {
					color: colors.primary,
				},
			}}
			_active={{}}
		>
			<Text transition="color 0.3s ease">{tab.title}</Text>
		</Tab>
	));

	if (!user) return null;

	const { firstName, lastName, email, userId } = user;
	const userInfo = `${firstName || ''} ${lastName || ''}`;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered blockScrollOnMount>
			<ModalOverlay backdropFilter="blur(3px)" />
			<ModalContent overflow="hidden" bg={colors.green[300]} borderRadius="10px" p="24px" boxShadow="none">
				<ModalHeader fontWeight="500" fontSize="20px" p="0" pr="34px" color={colors.white[100]}>
					{userInfo}
				</ModalHeader>
				<ModalCloseButton
					top="24px"
					right="24px"
					w="30px"
					h="30px"
					bg={colors.primary}
					borderRadius="8px"
					color={colors.black[100]}
					_hover={{
						opacity: '0.75',
					}}
					_active={{}}
					_focusVisible={{}}
				>
					<CloseIcon />
				</ModalCloseButton>
				<Flex rowGap="24px" mt="24px" direction="column">
					<HStack spacing="16px">
						<VStack spacing="16px" alignItems="flex-start" w="230px">
							<Text color={colors.primary}>Email:</Text>
							<Text variant="bodyLarge" maxW="230px" isTruncated>
								{email || '-'}
							</Text>
						</VStack>
						<VStack spacing="16px" alignItems="flex-start">
							<Text color={colors.primary}>User ID:</Text>
							<Text variant="bodyLarge">{userId || '-'}</Text>
						</VStack>
					</HStack>
					<Tabs isLazy bg="transparent" borderRadius="8px" p="0" h="100%">
						<TabList borderBottom="0px">{renderTabs}</TabList>

						<TabPanels h="calc(100% - 120px)">
							<TabPanel h="100%" p="0" mt="24px" display="flex" flexDirection="column">
								<RefillsTab userId={userId} onGetRefillsData={onGetRefillsData} onResetRefillsUserData={onResetRefillsUserData} />
							</TabPanel>
							<TabPanel h="100%" p="0" mt="24px" display="flex" flexDirection="column">
								<WithdrawalsTab
									userId={userId}
									onGetWithdrawalsData={onGetWithdrawalsData}
									onOpenChangeStatusModal={onOpenChangeStatusModal}
									onResetWithdrawalsUserData={onResetWithdrawalsUserData}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
					<Button variant="filled" w="full" onClick={onClose}>
						<Text>Ok</Text>
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
