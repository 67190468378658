import React from 'react';
import { colors } from '@/theme/colors';
import { BaseIconType } from '@/types';

export const ArrowForwardIcon: React.FC<BaseIconType> = ({
	width = '20px',
	height = '10px',
	fill = `${colors.white[100]}`,
	stroke = `${colors.white[100]}`,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 10" fill="none">
		<path d="M15 5H1" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M19.7152 4.79657L14.265 0.903565C13.7355 0.525354 13 0.903853 13 1.55455V8.44545C13 9.09615 13.7355 9.47465 14.265 9.09644L19.7152 5.20343C19.8548 5.10373 19.8548 4.89627 19.7152 4.79657Z"
			fill={fill}
		/>
	</svg>
);
