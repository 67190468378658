import React from 'react';
import Select, { CSSObjectWithLabel, Props, components } from 'react-select';
import { FormControl, FormLabel, InputLeftAddonProps, FormControlProps, Flex, Text, theme, Box } from '@chakra-ui/react';
import { colors } from '@/theme/colors';
import { ChevronDownIcon } from '@/assets/icons';

export interface ICustomSelectProps extends Props {
	label?: string;
	leftInputAddonProps?: InputLeftAddonProps;
	zIndex?: number;
	errorMsg?: string;
	borderRadius?: string | number;
	formControlProps?: FormControlProps;
	addonLabel?: string;
	fontSize?: string;
	dropdownIndicator?: JSX.Element;
	controlStyles?: any;
	inputStyles?: any;
	placeholderStyles?: any;
	dropdownIndicatorStyles?: any;
}
export const CustomSelect: React.FC<ICustomSelectProps> = ({
	label,
	leftInputAddonProps,
	zIndex = 2,
	errorMsg,
	borderRadius = '10px',
	formControlProps,
	addonLabel,
	fontSize = '14px',
	dropdownIndicator,
	controlStyles,
	inputStyles,
	placeholderStyles,
	dropdownIndicatorStyles,
	...rest
}) => {
	return (
		<FormControl
			isInvalid={!!errorMsg}
			borderRadius={borderRadius}
			bg={colors.bgMain}
			border="none"
			borderWidth={0}
			{...formControlProps}
			boxShadow="none"
			_hover={{
				boxShadow: 'none',
			}}
		>
			{label && (
				<FormLabel mb="4px" mr="0" fontSize="14px" lineHeight="21px" fontWeight={400} color="black.200">
					{label}
				</FormLabel>
			)}
			<Select
				styles={{
					control: baseStyles => ({
						...baseStyles,
						background: colors.bgMain,
						height: '40px',
						borderRadius: '10px',
						fontWeight: 500,
						color: colors.white[100],
						fontSize: '14px',
						cursor: 'pointer',
						paddingLeft: '16px',
						opacity: rest.isDisabled ? 0.5 : 1,
						border: 'none',
						boxShadow: 'none',
						':hover': {
							opacity: 0.75,
						},
						':focus-visible': {
							border: errorMsg ? `1px solid ${theme.colors.red[500]}` : `1px solid ${colors.black[100]}`,
						},
						...controlStyles,
					}),
					valueContainer: baseStyle =>
						({
							...baseStyle,
							padding: '0px',
						}) as CSSObjectWithLabel,
					input: baseStyle =>
						({
							...baseStyle,
							color: colors.primary,
							fontWeight: '400',
							bg: colors.bgMain,
							padding: '0',
							opacity: rest.isDisabled ? 0.5 : 1,
							...inputStyles,
						}) as CSSObjectWithLabel,
					option: (baseStyles, state) =>
						({
							...baseStyles,
							cursor: 'pointer',
							textTransform: 'capitalize',
							background: colors.bgMain,
							borderRadius: '10px',
							color: colors.white[100],
							fontSize: '14px',
							fontWeight: 500,
							':hover': { background: colors.green[300] },

							':active': {
								background: colors.green[400],
							},
						}) as CSSObjectWithLabel,
					placeholder: baseStyles => ({
						...baseStyles,
						color: `${colors.white[100]}`,
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '18px',
						padding: '0',
						opacity: 0.5,
						...placeholderStyles,
					}),
					singleValue: baseStyles =>
						({
							...baseStyles,
							color: colors.white[100],
							fontWeight: '500',
							padding: '0',
							fontSize: '14px',
							lineHeight: '18px',
							margin: '0px',
						}) as CSSObjectWithLabel,
					dropdownIndicator: (baseStyles, state) => ({
						...baseStyles,
						color: `${colors.primary}`,
						transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
						':hover': { color: `${colors.primary}` },
						...dropdownIndicatorStyles,
					}),
					menu: baseStyles =>
						({
							...baseStyles,
							marginTop: '4px',
							background: colors.bgMain,
							borderRadius: '10px',
							boxShadow: 'none',
						}) as CSSObjectWithLabel,
					menuList: baseStyles =>
						({
							...baseStyles,

							background: colors.bgMain,
							padding: '8px',
							borderRadius: '10px',
						}) as CSSObjectWithLabel,
				}}
				components={{
					SingleValue: ({ children, ...props }) => {
						return (
							<components.SingleValue {...props}>
								{addonLabel || ''} {children}
							</components.SingleValue>
						);
					},
					Placeholder: ({ children, ...props }) => {
						return (
							<components.Placeholder {...props}>
								{addonLabel || ''} {children}
							</components.Placeholder>
						);
					},
					IndicatorSeparator: () => null,
					DropdownIndicator: ({ selectProps }) =>
						dropdownIndicator ? (
							dropdownIndicator
						) : (
							<Flex justifyContent="center" alignItems="center" pr="8px">
								<Box transition="transform 0.2s ease" transform={`rotate(${selectProps.menuIsOpen ? '-180' : '0'}deg)`}>
									<ChevronDownIcon />
								</Box>
							</Flex>
						),
				}}
				{...rest}
			/>
			<Text variant="bodyMedium" color="red.500">
				{errorMsg}
			</Text>
		</FormControl>
	);
};
