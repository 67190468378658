import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { LogoutIcon } from '@/assets/icons';
import { colors } from '@/theme/colors';

interface ILogoutButton {
	onLogout: () => void;
	onlyIcon?: boolean;
	loading: boolean;
}

export const LogoutButton: React.FC<ILogoutButton> = ({ onLogout, loading, onlyIcon }) => {
	return (
		<Button
			variant="border"
			w={onlyIcon ? '24px' : '100%'}
			minW={onlyIcon ? '24px' : '40px'}
			maxW="300px"
			columnGap="8px"
			border={onlyIcon ? 'none' : `1px solid ${colors.primary}`}
			px={onlyIcon ? '0px' : '16px'}
			isLoading={loading}
			onClick={onLogout}
		>
			<LogoutIcon />
			{!onlyIcon ? <Text>Log out</Text> : null}
		</Button>
	);
};
