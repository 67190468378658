import { CommissionValueType } from '@/enums';
import { normalizePrice } from '@/utils';

export const getCommissionByType = (type?: number, value?: number) => {
	switch (type) {
		case CommissionValueType.EMPTY:
			return '0';
		case CommissionValueType.PERCENTAGE:
			return `${value ? value / 100 : 0}%`;
		case CommissionValueType.FIXED_RATE:
			return normalizePrice(value);
		default:
			return '0';
	}
};
