export enum ErrorMessages {
	REQUIRED = 'This field is required',
	EMAIL_INVALID = 'Email is invalid',
	PASSWORD_MATCH = 'Passwords do not match',
	SHORT_PASSWORD = 'Password should be minimum 8 characters long',
	PASSWORD_DIGIT = 'Password should have at least one number',
	PASSWORD_UPPERCASE = 'Password should have at least one uppercase character',
	NON_ALPHANUMERIC_MISSING = 'Password should have at least one non-alphanumeric character',
	MINIMUM_4_CHARS = 'Password should have at least 4 unique characters',
}
