export const colors = {
	primary: '#4AD1C6',
	bgMain: '#1A202C',
	white: {
		100: '#ffffff',
	},
	black: {
		100: '#000000',
		200: '#121720',
	},
	green: {
		100: '#1A524E',
		200: '#133633',
		300: '#1F2733',
		400: '#165b56',
		500: '#29817A',
		600: '#44BB7C',
		700: '#02efd9',
		800: '#1f6e67',
	},
	gray: {
		100: '#505050',
		200: '#39404f',
		300: '#6d7b91',
	},
	blue: {
		100: '#3F99DF',
		200: '#39404f',
	},
};
