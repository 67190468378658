import React from 'react';
import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { LoginForm } from '@/pages/SignIn/components/LoginForm';

const SignIn: React.FC = () => {
	const titleColor = useColorModeValue('teal.300', 'teal.200');
	const textColor = useColorModeValue('gray.400', 'white');
	return (
		<Flex position="relative">
			<Flex
				h={{ sm: 'initial', md: '100%', lg: '100%' }}
				minH={'100vh'}
				w="100%"
				maxW="1044px"
				mx="auto"
				justifyContent="space-between"
				pt={{ sm: '100px', md: '0px' }}
			>
				<Flex alignItems="center" justifyContent="start" style={{ userSelect: 'none' }} w={{ base: '100%', md: '50%', lg: '42%' }}>
					<Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: '150px', lg: '80px' }}>
						<Heading alignSelf={'center'} color={titleColor} fontSize="32px" mb="10px">
							Welcome Back
						</Heading>
						<Text fontSize="xl" color={textColor} fontWeight="bold" textAlign="center" mb="22px">
							Admin Panel
						</Text>
						<LoginForm />
					</Flex>
				</Flex>
				<Box display={{ base: 'none', md: 'block' }} overflowX="hidden" h="100%" w="40vw" position="absolute" right="0px">
					<Box bg="teal" w="100%" h="100%" bgSize="cover" bgPosition="50%" position="absolute" borderBottomLeftRadius="20px" />
				</Box>
			</Flex>
		</Flex>
	);
};

export default SignIn;
