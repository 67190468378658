import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfinitePaginationPayload, InfinitePaginationResponse, PayloadError } from '@/types';
import {
	AvailableBalanceResponse,
	CommissionEditPayload,
	CommissionHistoryItem,
	CommissionInfoResponse,
	CurrentCommissionData,
	WithdrawalHistoryItem,
	WithdrawalInfoResponse,
} from '../types';

interface InitialState {
	commissionInfo: {
		data: CommissionHistoryItem[];
		totalCount: number;
		currentCommission: CurrentCommissionData | null;
		loading: boolean;
		errors: PayloadError[];
	};
	withdrawalsInfo: {
		data: WithdrawalHistoryItem[];
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};
	balance: {
		amount: number;
		commissionAmount: number;
		loading: boolean;
		errors: PayloadError[];
	};
	updateCommission: {
		loading: boolean;
		success: boolean;
		errors: PayloadError[];
	};
	updateWithdrawals: {
		loading: boolean;
		success: boolean;
		errors: PayloadError[];
	};
}

const initialState: InitialState = {
	commissionInfo: {
		data: [],
		totalCount: 0,
		currentCommission: null,
		loading: false,
		errors: [],
	},
	withdrawalsInfo: {
		data: [],
		totalCount: 0,
		loading: false,
		errors: [],
	},
	balance: {
		amount: 0,
		commissionAmount: 0,
		loading: false,
		errors: [],
	},
	updateCommission: {
		loading: false,
		success: false,
		errors: [],
	},
	updateWithdrawals: {
		loading: false,
		success: false,
		errors: [],
	},
};

const commissionReducer = createSlice({
	name: 'commission',
	initialState,
	reducers: {
		getCommissionInfoRequest: (state, _action: PayloadAction<InfinitePaginationPayload>) => {
			state.commissionInfo.loading = true;
			state.commissionInfo.errors = [];
		},
		getCommissionInfoSuccess: (state, action: PayloadAction<InfinitePaginationResponse<CommissionInfoResponse>>) => {
			const { data, getMore } = action.payload;
			const { totalCount, currentCommission, commissionHistory } = data;
			if (getMore) {
				state.commissionInfo.data = [...state.commissionInfo.data, ...commissionHistory];
			} else {
				state.commissionInfo.data = commissionHistory;
			}
			state.commissionInfo.totalCount = totalCount;
			state.commissionInfo.currentCommission = currentCommission;
			state.commissionInfo.loading = false;
		},
		getCommissionInfoErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.commissionInfo.loading = false;
			state.commissionInfo.errors = action.payload;
		},

		resetCommissionInfoState: state => {
			return {
				...state,
				commissionInfo: { ...initialState.commissionInfo },
			};
		},

		getWithdrawalsInfoRequest: (state, _action: PayloadAction<InfinitePaginationPayload>) => {
			state.withdrawalsInfo.loading = true;
			state.withdrawalsInfo.errors = [];
		},
		getWithdrawalsInfoSuccess: (state, action: PayloadAction<InfinitePaginationResponse<WithdrawalInfoResponse>>) => {
			const { data, getMore } = action.payload;
			const { count, history } = data;
			if (getMore) {
				state.withdrawalsInfo.data = [...state.withdrawalsInfo.data, ...history];
			} else {
				state.withdrawalsInfo.data = history;
			}
			state.withdrawalsInfo.totalCount = count;
			state.withdrawalsInfo.loading = false;
		},
		getWithdrawalsInfoErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.withdrawalsInfo.loading = false;
			state.withdrawalsInfo.errors = action.payload;
		},

		getBalanceRequest: state => {
			state.balance.loading = true;
			state.balance.errors = [];
		},
		getBalanceSuccess: (state, action: PayloadAction<AvailableBalanceResponse>) => {
			const { amount, commissionAmount } = action.payload;

			state.balance.amount = amount;
			state.balance.commissionAmount = commissionAmount;

			state.balance.loading = false;
		},
		getBalanceErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.balance.loading = false;
			state.balance.errors = action.payload;
		},

		resetWithdrawalsInfoState: state => {
			return {
				...state,
				withdrawalsInfo: { ...initialState.withdrawalsInfo },
			};
		},

		updateCommissionRequest: (state, _action: PayloadAction<CommissionEditPayload>) => {
			state.updateCommission.loading = true;
			state.updateCommission.errors = [];
		},
		updateCommissionSuccess: (state, _action: PayloadAction<CommissionEditPayload>) => {
			state.updateCommission.loading = false;
			state.updateCommission.success = true;
		},
		updateCommissionErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.updateCommission.loading = false;
			state.updateCommission.errors = action.payload;
		},

		resetUpdateCommissionState: state => {
			return {
				...state,
				updateCommission: { ...initialState.updateCommission },
			};
		},

		updateWithdrawalsRequest: (state, _action: PayloadAction<{ amount: number }>) => {
			state.updateWithdrawals.loading = true;
			state.updateWithdrawals.errors = [];
		},
		updateWithdrawalsSuccess: (state, _action: PayloadAction<unknown>) => {
			state.updateWithdrawals.loading = false;
			state.updateWithdrawals.success = true;
		},
		updateWithdrawalsErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.updateWithdrawals.loading = false;
			state.updateWithdrawals.errors = action.payload;
		},
		resetUpdateWithdrawalsState: state => {
			return {
				...state,
				updateWithdrawals: { ...initialState.updateWithdrawals },
			};
		},
	},
});

export const {
	getCommissionInfoRequest,
	getCommissionInfoSuccess,
	getCommissionInfoErrors,
	resetCommissionInfoState,

	getWithdrawalsInfoRequest,
	getWithdrawalsInfoSuccess,
	getWithdrawalsInfoErrors,
	resetWithdrawalsInfoState,

	getBalanceRequest,
	getBalanceSuccess,
	getBalanceErrors,

	updateCommissionRequest,
	updateCommissionSuccess,
	updateCommissionErrors,
	resetUpdateCommissionState,

	updateWithdrawalsRequest,
	updateWithdrawalsSuccess,
	updateWithdrawalsErrors,
	resetUpdateWithdrawalsState,
} = commissionReducer.actions;

export default commissionReducer.reducer;
