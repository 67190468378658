import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfinitePaginationPayload, InfinitePaginationResponse, PayloadError } from '@/types';
import { StatItem, UsersItem, UsersResponse } from '../types';

interface InitialState {
	users: {
		data: UsersItem[];
		totalCount: number;
		botsEnabled: boolean | null;
		loading: boolean;
		errors: PayloadError[];
	};
	userMatchHistory: {
		data: StatItem[];
		totalCount: number;
		loading: boolean;
		errors: PayloadError[];
	};
}

const initialState: InitialState = {
	users: {
		data: [],
		totalCount: 0,
		botsEnabled: null,
		loading: false,
		errors: [],
	},
	userMatchHistory: {
		data: [],
		totalCount: 0,
		loading: false,
		errors: [],
	},
};

const usersReducer = createSlice({
	name: 'users',
	initialState,
	reducers: {
		getUsersRequest: (state, _action: PayloadAction<InfinitePaginationPayload>) => {
			state.users.loading = true;
			state.users.errors = [];
		},
		getUsersSuccess: (state, action: PayloadAction<InfinitePaginationResponse<UsersResponse>>) => {
			const { getMore, data } = action.payload;
			const { totalCount, users } = data;
			if (getMore) {
				state.users.data = [...state.users.data, ...users];
			} else {
				state.users.data = users;
			}
			state.users.totalCount = totalCount;
			state.users.loading = false;
		},
		getUsersErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.users.loading = false;
			state.users.errors = action.payload;
		},

		resetUsersState: state => {
			state.users.data = [];
		},
		getUserMatchHistoryRequest: (state, _action: PayloadAction<{ currentPage: number; userId: number; getMore: boolean }>) => {
			state.userMatchHistory.loading = true;
			state.userMatchHistory.errors = [];
		},
		getUserMatchHistorySuccess: (
			state,
			action: PayloadAction<{
				gameStatistics: StatItem[];
				count: number;
				getMore: boolean;
			}>,
		) => {
			if (action.payload.getMore) {
				state.userMatchHistory.data = [...state.userMatchHistory.data, ...action.payload.gameStatistics];
			} else {
				state.userMatchHistory.data = action.payload.gameStatistics;
			}
			state.userMatchHistory.loading = false;
			state.userMatchHistory.totalCount = action.payload.count;
		},
		getUserMatchHistoryErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.userMatchHistory.loading = false;
			state.userMatchHistory.errors = action.payload;
		},
		resetUserMatchHistoryState: state => {
			state.userMatchHistory.data = [];
		},
		updateUserRequest: (state, action: PayloadAction<{ userId: number; isBot: boolean }>) => {
			state.users.errors = [];
		},

		updateUserSuccess: (state, action: PayloadAction<{ userId: number; isBot: boolean }>) => {
			state.users.data = state.users.data.map(user => {
				if (user.id === action.payload.userId) {
					return { ...user, isBot: action.payload.isBot };
				}
				return user;
			});
		},
		updateUserErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.users.errors = action.payload;
		},
		updateUserBlockRequest: (state, action: PayloadAction<{ userId: number; isBlocked: boolean }>) => {
			state.users.errors = [];
		},

		updateUserBlockSuccess: (state, action: PayloadAction<{ userId: number; isBlocked: boolean }>) => {
			state.users.data = state.users.data.map(user => {
				if (user.id === action.payload.userId) {
					return { ...user, isBlocked: action.payload.isBlocked };
				}
				return user;
			});
		},
		updateUserBlockErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.users.errors = action.payload;
		},
		getAdminGameConfigRequest: state => {
			state.users.errors = [];
		},
		getAdminGameConfigSuccess: (state, action: PayloadAction<{ botsEnabled: boolean }>) => {
			state.users.botsEnabled = action.payload.botsEnabled;
		},
		getAdminGameConfigErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.users.errors = action.payload;
		},
		updateAdminGameConfigRequest: state => {
			state.users.errors = [];
		},
		updateAdminGameConfigSuccess: (state, action: PayloadAction<{ botsEnabled: boolean }>) => {
			state.users.botsEnabled = action.payload.botsEnabled;
		},
		updateAdminGameConfigErrors: (state, action: PayloadAction<PayloadError[]>) => {
			state.users.errors = action.payload;
		},
	},
});

export const {
	getUsersRequest,
	getUsersSuccess,
	getUsersErrors,
	resetUsersState,
	getUserMatchHistoryRequest,
	getUserMatchHistorySuccess,
	getUserMatchHistoryErrors,
	resetUserMatchHistoryState,
	updateUserRequest,
	updateUserSuccess,
	updateUserErrors,
	updateUserBlockRequest,
	updateUserBlockSuccess,
	updateUserBlockErrors,
	getAdminGameConfigRequest,
	getAdminGameConfigSuccess,
	getAdminGameConfigErrors,
	updateAdminGameConfigRequest,
	updateAdminGameConfigSuccess,
	updateAdminGameConfigErrors,
} = usersReducer.actions;

export default usersReducer.reducer;
