import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponseEmpty, SagaError } from '@/types';
import API from '../../../api/api.base';
import {
	resetPasswordError,
	resetPasswordRequest,
	resetPasswordSuccess,
} from '../../reducers/auth.reducer';
import { Alerter, History } from '@/utils';

function* workerResetPassword(action: ReturnType<typeof resetPasswordRequest>) {
	try {
		const result: ApiResponseEmpty = yield call(
			API.post,
			'/api/v1/auth/reset-password',
			action.payload,
		);
		if (result.success) {
			yield put(resetPasswordSuccess());
			History.push('/');
			Alerter.success(result.messages[0].message);
		} else {
			yield put(resetPasswordError(result.errors));
			Alerter.error(result.errors[0].message);
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

export default function* watchResetPasswordSaga() {
	yield takeEvery(resetPasswordRequest.type, workerResetPassword);
}
