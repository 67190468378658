import React from 'react';
import { BaseIconType } from '@/types';

export const LogoutIcon: React.FC<BaseIconType> = ({ width = '24px', height = '24px', fill = 'white' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24" fill="none">
		<path
			d="M8.5 18.9282C9.71615 19.6303 11.0957 20 12.5 20C13.9043 20 15.2838 19.6303 16.5 18.9282C17.7162 18.2261 18.7261 17.2162 19.4282 16C20.1303 14.7838 20.5 13.4043 20.5 12C20.5 10.5957 20.1303 9.21615 19.4282 8C18.7261 6.78385 17.7162 5.77394 16.5 5.0718C15.2838 4.36965 13.9043 4 12.5 4C11.0957 4 9.71615 4.36965 8.5 5.0718"
			stroke={fill}
			strokeWidth="2"
		/>
		<path
			d="M2.5 12L1.71913 11.3753L1.21938 12L1.71913 12.6247L2.5 12ZM11.5 13C12.0523 13 12.5 12.5523 12.5 12C12.5 11.4477 12.0523 11 11.5 11V13ZM5.71913 6.3753L1.71913 11.3753L3.28087 12.6247L7.28087 7.6247L5.71913 6.3753ZM1.71913 12.6247L5.71913 17.6247L7.28087 16.3753L3.28087 11.3753L1.71913 12.6247ZM2.5 13H11.5V11H2.5V13Z"
			fill={fill}
		/>
	</svg>
);
