import React from 'react';
import { Button, Flex, FlexProps, NumberInput, NumberInputField, Tooltip } from '@chakra-ui/react';
import { colors } from '@/theme/colors';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { onlyDigits } from '@/utils';

interface IQuantitySelector {
	onChange: (...event: string[]) => void;
	value: string;
	min?: number;
	max?: number;
	containerProps?: FlexProps;
}

export const QuantitySelector: React.FC<IQuantitySelector> = ({ min = 1, max = 100, onChange, value, containerProps }) => {
	return (
		<Flex maxW="150px" borderRadius="10px" overflow="hidden" w="200px" h="40px" {...containerProps}>
			<Button
				bg={colors.primary}
				h="40px"
				w="40px"
				minW="40px"
				maxW="40px"
				borderRadius="0px"
				isDisabled={+value === min || !value}
				_hover={{
					opacity: 0.75,
				}}
				_active={{
					opacity: 0.6,
				}}
				_disabled={{
					opacity: 0.4,
					cursor: 'not-allowed',
				}}
				onClick={() => {
					if (+value > 1 && +value > min) {
						return onChange(`${+value - 1}`);
					}
				}}
			>
				<MinusIcon fontSize="16px" color={colors.black[100]} />
			</Button>
			<NumberInput
				value={value || ''}
				onChange={event => {
					const inputValue = onlyDigits(event);
					onChange(inputValue);
				}}
				max={max}
				min={min}
			>
				<NumberInputField
					w="100%"
					px="16px"
					bg="white"
					border="none"
					h="40px"
					color={colors.black[100]}
					fontSize="16px"
					fontWeight={500}
					borderRadius="0px"
					textAlign="center"
					_invalid={{}}
					_focusVisible={{}}
				/>
			</NumberInput>
			<Button
				bg={colors.primary}
				h="40px"
				w="40px"
				minW="40px"
				maxW="40px"
				borderRadius="0px"
				isDisabled={+value === max}
				_hover={{
					opacity: 0.75,
				}}
				_active={{
					opacity: 0.6,
				}}
				_disabled={{
					opacity: 0.4,
					cursor: 'not-allowed',
				}}
				onClick={() => {
					if (+value < max) {
						onChange(`${+value + 1}`);
					}
				}}
			>
				<AddIcon fontSize="16px" color={colors.black[100]} />
			</Button>
		</Flex>
	);
};
