import React from 'react';
import { ForgotPassword } from './ForgotPassword';

export const ForgotPasswordContainer: React.FC = () => {
	return (
		<>
			<ForgotPassword />
		</>
	);
};
