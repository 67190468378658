import React from 'react';
import * as yup from 'yup';
import { Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { InputText } from '@/components';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '@/validation/messages';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '@/store';
import { resetPasswordEmailRequest } from '@/store/reducers/auth.reducer';

const _resolver = yupResolver(
	yup.object({
		email: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
	}),
);

export const ForgotPassword: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootStore) => state.auth);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ email: string }>({
		resolver: _resolver,
	});
	const onSubmit = (data: { email: string }) => {
		dispatch(resetPasswordEmailRequest(data));
	};
	return (
		<Flex h={'100vh'} alignItems="center" justifyContent="center" mb={{ base: '0px', md: '60px' }} mt={{ base: '0px', md: '20px' }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex
					direction="column"
					w={{ base: '100%', md: '445px' }}
					borderRadius={{ base: '0px', md: '16px' }}
					p={{ base: '24px', md: '40px' }}
					mx={{ base: '0px', md: '100px' }}
					bg={'#1F2733'}
					boxShadow={'base'}
				>
					<Flex mb={'28px'} justifyContent={'center'} w={'full'} pos={'relative'} alignItems={'center'}>
						<IconButton
							borderRadius={'15px'}
							bg={'#313843'}
							_hover={{ bg: '#434A54' }}
							onClick={() => navigate(-1)}
							position={'absolute'}
							left={0}
							top={-2}
							aria-label={'back'}
							icon={<BiArrowBack color={'white'} />}
						/>
						<Text fontSize="xl" color={'white'} fontWeight="bold" textAlign="center">
							Change password
						</Text>
					</Flex>
					<InputText
						{...register('email')}
						borderRadius={'12px'}
						border={'1px solid'}
						borderColor={'#ffffff29'}
						bg={'inherit'}
						minH={'48px'}
						fontSize={'14px'}
						p={'0 16px'}
						_hover={{}}
						_focusVisible={{}}
						label={'Email'}
						placeholder={'Enter email'}
						errorMsg={errors.email?.message}
					/>
					<Button
						isLoading={loading}
						type="submit"
						bg="teal.300"
						w="100%"
						h="45"
						borderRadius={'15px'}
						mb="20px"
						color="white"
						mt="20px"
						fontSize={'10px'}
						_hover={{
							bg: 'teal.200',
						}}
						_active={{
							bg: 'teal.400',
						}}
					>
						SEND
					</Button>
				</Flex>
			</form>
		</Flex>
	);
};
