import { Grid, Button, MenuButton, Menu, MenuList, MenuItem } from '@chakra-ui/react';
import { colors } from '@/theme/colors';
import { UsersTableCell } from '@/pages/Users/components/UsersTableCell';
import { RoleType, StateType, UsersItem } from '@/pages/Users/types';
import { BotIcon, ChevronDownIcon, UserIcon } from '@/assets/icons';
import { MatchResultsIcon } from '@/assets/icons/MatchIcon';
import React from 'react';

interface IUsersTableRow extends UsersItem {
	onModalOpen: VoidFunction;
	onSelectItemChange: (isBot: boolean) => void;
	onSelectItemChangeState: (isBlocked: boolean) => void;
}

export const UsersTableRow: React.FC<IUsersTableRow> = ({
	id,
	name,
	userName,
	email,
	credits,
	phone,
	address,
	wins,
	losses,
	isBot,
	isBlocked,
	onModalOpen,
	onSelectItemChange,
	onSelectItemChangeState,
}) => {
	const handelSelect = (value: boolean) => {
		if (isBot === value) return;
		onSelectItemChange(value);
	};
	const handelSelectState = (value: boolean) => {
		if (isBlocked === value) return;
		onSelectItemChangeState(value);
	};

	return (
		<Grid
			templateColumns="repeat(12, minmax(100px, 1fr))"
			bg={colors.bgMain}
			gap="50px"
			borderRadius="10px"
			px="16px"
			mt="4px"
			transition="background 0.3s ease"
			alignItems={'center'}
			_hover={{
				background: colors.green[400],
			}}
		>
			<UsersTableCell title={id} />
			<UsersTableCell title={name} />
			<UsersTableCell title={userName} />
			<UsersTableCell title={address} noOfLines={2} />
			<UsersTableCell title={phone} />
			<UsersTableCell title={email} />
			<UsersTableCell title={credits} />
			<UsersTableCell title={`${wins} / ${losses}`} />
			<UsersTableCell title={isBot ? <BotIcon /> : <UserIcon fill={colors.green[800]} />} />
			<UsersTableCell
				title={
					<Menu>
						<MenuButton
							w={'90px'}
							px={'10px'}
							as={Button}
							variant="outline"
							borderRadius={'10px'}
							rightIcon={<ChevronDownIcon width={'18px'} height={'18px'} />}
							color={'white'}
							fontSize={'14px'}
							_focus={{ borderColor: colors.primary }}
							_hover={{ bg: colors.blue[200] }}
						>
							{isBot ? RoleType.BOT : RoleType.HUMAN}
						</MenuButton>
						<MenuList minW={'90px'} bg={colors.green[300]}>
							<MenuItem onClick={() => handelSelect(false)} bg={colors.green[300]} _hover={{ bg: colors.blue[200] }}>
								{RoleType.HUMAN}
							</MenuItem>
							<MenuItem onClick={() => handelSelect(true)} bg={colors.green[300]} _hover={{ bg: colors.blue[200] }}>
								{RoleType.BOT}
							</MenuItem>
						</MenuList>
					</Menu>
				}
			/>
			<UsersTableCell
				title={
					<Menu>
						<MenuButton
							w={'100px'}
							px={'10px'}
							as={Button}
							variant="outline"
							borderRadius={'10px'}
							rightIcon={<ChevronDownIcon width={'18px'} height={'18px'} />}
							color={'white'}
							fontSize={'14px'}
							_focus={{ borderColor: colors.primary }}
							_hover={{ bg: colors.blue[200] }}
						>
							{isBlocked ? StateType.BLOCKED : StateType.ACTIVE}
						</MenuButton>
						<MenuList minW={'100px'} bg={colors.green[300]}>
							<MenuItem onClick={() => handelSelectState(false)} bg={colors.green[300]} _hover={{ bg: colors.blue[200] }}>
								{StateType.ACTIVE}
							</MenuItem>
							<MenuItem onClick={() => handelSelectState(true)} bg={colors.green[300]} _hover={{ bg: colors.blue[200] }}>
								{StateType.BLOCKED}
							</MenuItem>
						</MenuList>
					</Menu>
				}
			/>
			<UsersTableCell
				title={
					<Button
						variant="outline"
						borderColor={colors.green[800]}
						_hover={{ backgroundColor: colors.blue[200], borderColor: colors.green[700] }}
						onClick={onModalOpen}
						w={'50px'}
					>
						<MatchResultsIcon />
					</Button>
				}
			/>
		</Grid>
	);
};
