import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '@/components';
import { colors } from '@/theme/colors';
import { CustomHorizontalScrollStyles } from '@/constants';
import { InfinitePaginationPayload } from '@/types';
import { TABLE_TH } from '../constants';
import { WithdrawManagementItem } from '../types';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import { useSelector } from 'react-redux';
import { RootStore } from '@/store';

const pageSize = 10;

interface ITableData {
	onGetWithdrawTable: (payload: InfinitePaginationPayload) => void;
	onSelectUser: (user: WithdrawManagementItem) => void;
	onResetWithdrawTable: () => void;
}

export const TableData: React.FC<ITableData> = ({ onGetWithdrawTable, onSelectUser, onResetWithdrawTable }) => {
	const [page, setPage] = useState(1);

	const { data: users, totalCount, loading } = useSelector((state: RootStore) => state.withdrawManagement.withdrawManagement);

	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	const hasMore = useMemo(() => users.length < totalCount, [users.length, totalCount]);

	useEffect(() => {
		onGetWithdrawTable({
			currentPage: 1,
			getMore: false,
			pageSize,
		});
		setPage(1);

		return () => {
			onResetWithdrawTable();
		};
	}, []);
	useEffect(() => {
		if (page !== 1) {
			onGetWithdrawTable({
				currentPage: page,
				getMore: true,
				pageSize,
			});
		}
	}, [page]);

	const renderTh = TABLE_TH.map(th => (
		<TableCell
			key={th.id}
			title={th.title}
			textProps={{
				variant: 'bodyMedium',
				textTransform: 'none',
				color: colors.primary,
			}}
		/>
	));
	return (
		<Box w="100%" overflowX="auto" pb="4px" sx={CustomHorizontalScrollStyles}>
			<Grid
				templateColumns="100px 100px 180px 80px 154px"
				gap="50px"
				minW="850px"
				w="100%"
				px="16px"
				bg="black"
				borderRadius="10px"
				mb="4px"
			>
				{renderTh}
			</Grid>
			<InfiniteScroll
				dataLength={users.length}
				next={() => {
					if (!loading) {
						onPageChange();
					}
				}}
				hasMore={hasMore}
				loader={
					page !== 1 &&
					loading && (
						<Flex h="50px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
							<Loader centerHeight="40px" spinnerSize="sm" />
						</Flex>
					)
				}
				height="auto"
				className="infinite-scroll-section"
				style={{
					maxHeight: 'calc(100vh - 280px)',
					minWidth: '850px',
					width: 'auto',
					paddingRight: '0px',
					overflowX: 'hidden',
				}}
			>
				{loading && page === 1 ? (
					<GridItem colSpan={5} mt="4px" borderRadius="10px" overflow="hidden" bg={colors.bgMain}>
						<Loader centerHeight="50px" spinnerSize="md" />
					</GridItem>
				) : (
					<>
						{users.map((item: WithdrawManagementItem) => (
							<TableRow key={item.userId} onSelectUser={() => onSelectUser(item)} {...item} />
						))}
					</>
				)}
			</InfiniteScroll>
		</Box>
	);
};
