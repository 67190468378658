import React from 'react';
import { Box, BoxProps, Flex, StackProps, Text, VStack } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@/assets/icons';
import { colors } from '@/theme/colors';
import { toDisplayedDate } from '@/utils';

interface IHistoryItem {
	date: string;
	valueBefore: string;
	valueAfter: string;
	titleBefore?: string;
	titleAfter?: string;
	balanceAfterValue?: string;
	exchangeColor?: string;
	arrowBack?: boolean;
	containerProps?: BoxProps;
	sectionBeforeProps?: StackProps;
	sectionAfterProps?: StackProps;
}

export const HistoryItem: React.FC<IHistoryItem> = ({
	date,
	valueBefore,
	valueAfter,
	balanceAfterValue,
	arrowBack,
	titleBefore = 'before',
	titleAfter = 'after',
	exchangeColor = colors.white[100],
	sectionBeforeProps,
	sectionAfterProps,
	containerProps,
}) => {
	const formattedDate = toDisplayedDate(date);
	return (
		<Box p="16px" bg={colors.bgMain} borderRadius="10px" {...containerProps}>
			<Flex justifyContent="space-between" columnGap="8px">
				<VStack spacing="8px" align="flex-start" w="70px">
					<Text variant="bodyMedium" color={colors.primary}>
						Date:
					</Text>
					<Text>{formattedDate}</Text>
				</VStack>

				<Flex align="center" columnGap="16px">
					<VStack spacing="8px" align="flex-start" w="90px" {...sectionBeforeProps}>
						<Text variant="bodyMedium" color={colors.primary} _firstLetter={{ textTransform: 'capitalize' }}>
							{titleBefore}:
						</Text>
						<Text>{valueBefore}</Text>
					</VStack>

					<Box transform={arrowBack ? 'rotate(180deg)' : 'rotate(0deg)'}>
						<ArrowForwardIcon fill={exchangeColor} stroke={exchangeColor} />
					</Box>

					<VStack spacing="8px" align="flex-start" w="90px" {...sectionAfterProps}>
						<Text variant="bodyMedium" color={colors.primary} _firstLetter={{ textTransform: 'capitalize' }}>
							{titleAfter}:
						</Text>
						<Text color={exchangeColor}>{valueAfter}</Text>
					</VStack>
				</Flex>

				{balanceAfterValue ? (
					<VStack spacing="8px" align="flex-start">
						<Text variant="bodyMedium" color={colors.primary}>
							Balance after:
						</Text>
						<Text>{balanceAfterValue}</Text>
					</VStack>
				) : null}
			</Flex>
		</Box>
	);
};
