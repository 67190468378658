import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const resetPasswordSchema = yup.object({
	password: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.min(8, ErrorMessages.SHORT_PASSWORD)
		.matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
		.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE)
		.matches(/\W/g, ErrorMessages.NON_ALPHANUMERIC_MISSING)
		.test(
			'unique-chars',
			ErrorMessages.MINIMUM_4_CHARS,
			function (value?: string) {
				return new Set(value?.split('')).size >= 4;
			},
		),
	confirmPassword: yup
		.string()
		.min(8, ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.test('password-match', ErrorMessages.PASSWORD_MATCH, function (value) {
			const { password } = this.parent;
			return password === value;
		}),
});

export const resetPasswordResolver = yupResolver(resetPasswordSchema);
