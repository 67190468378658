import React from 'react';
import { ResetPassword } from './ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { ResetPasswordForm } from '@/types';
import { useLocation } from 'react-router-dom';
import { RootStore } from '@/store';
import { resetPasswordRequest } from '@/store/reducers/auth.reducer';

export const ResetPasswordContainer: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const onReset = (data: ResetPasswordForm) => {
		const parsedParams = queryString.parse(location.search, {
			decode: false,
		});
		if (typeof parsedParams.id === 'string' && typeof parsedParams.token === 'string') {
			dispatch(
				resetPasswordRequest({
					...data,
					id: parsedParams.id,
					token: parsedParams.token.replace(/\s/g, '+'),
				}),
			);
		} else {
			alert('There was error with parsing data, please open link again');
		}
	};
	const { loading } = useSelector((state: RootStore) => state.auth);
	return <ResetPassword onReset={onReset} loading={loading} />;
};
