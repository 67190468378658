import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '@/theme/colors';
import { InfinitePaginationPayload } from '@/types';
import { TableData } from './components';
import { UserDetailsModal, ChangeWithdrawalStatusModal } from './modals';
import { UserDetailsPayload, WithdrawManagementItem } from './types';
import {
	getUserRefillsRequest,
	getUserWithdrawalsRequest,
	getWithdrawManagementRequest,
	resetUpdateWithdrawalStatusState,
	resetUserRefillsState,
	resetUserWithdrawalsState,
	resetWithdrawManagementState,
	updateWithdrawalStatusRequest,
} from './store/reducer';

const WithdrawManagement: React.FC = () => {
	const dispatch = useDispatch();

	const [user, setUser] = useState<WithdrawManagementItem | null>(null);

	const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();

	const onGetWithdrawTable = (payload: InfinitePaginationPayload) => dispatch(getWithdrawManagementRequest(payload));
	const onGetRefillsUserData = (payload: UserDetailsPayload) => dispatch(getUserRefillsRequest(payload));
	const onGetWithdrawalsUserData = (payload: UserDetailsPayload) => dispatch(getUserWithdrawalsRequest(payload));
	const onCancelNearestWithdrawalStatus = (userId: number) => dispatch(updateWithdrawalStatusRequest({ userId }));
	const onResetWithdrawTable = () => dispatch(resetWithdrawManagementState());
	const onResetRefillsUserData = () => dispatch(resetUserRefillsState());
	const onResetWithdrawalsUserData = () => dispatch(resetUserWithdrawalsState());
	const onResetUpdateWithdrawalStatusState = (userId: number) => dispatch(resetUpdateWithdrawalStatusState({ userId }));

	return (
		<>
			<Box pt={{ base: '30px', lg: '50px' }} pb="30px">
				<Heading variant="h2">Withdraw Management</Heading>
				<Box overflow="hidden" h="auto">
					<Box
						w="100%"
						maxW="884px"
						mt="32px"
						p="16px 16px 12px 16px"
						borderRadius="10px"
						bg={colors.green[300]}
						maxH="calc(100vh - 170px)"
						overflowX="hidden"
						border={`1px solid ${colors.green[200]}`}
					>
						<TableData
							onGetWithdrawTable={onGetWithdrawTable}
							onResetWithdrawTable={onResetWithdrawTable}
							onSelectUser={user => setUser(user)}
						/>
					</Box>
				</Box>
			</Box>

			<UserDetailsModal
				isOpen={!!user}
				onClose={() => setUser(null)}
				user={user}
				onGetRefillsData={onGetRefillsUserData}
				onGetWithdrawalsData={onGetWithdrawalsUserData}
				onOpenChangeStatusModal={onOpenChangeStatusModal}
				onResetRefillsUserData={onResetRefillsUserData}
				onResetWithdrawalsUserData={onResetWithdrawalsUserData}
			/>

			<ChangeWithdrawalStatusModal
				isOpen={isChangeStatusModalOpen}
				onClose={onCloseChangeStatusModal}
				onSubmit={() => {
					if (user?.userId) {
						onCancelNearestWithdrawalStatus(user.userId);
					}
				}}
				onReset={() => {
					if (user?.userId) {
						onResetUpdateWithdrawalStatusState(user.userId);
					}
				}}
			/>
		</>
	);
};

export default WithdrawManagement;
