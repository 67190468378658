import { call, put, takeEvery, ForkEffect, select } from 'redux-saga/effects';
import API from '@/api/api.base';
import { Alerter } from '@/utils';
import { SagaError } from '@/types';
import { RootStore } from '@/store';
import { logoutRequest, logoutSuccess } from '../../reducers/auth.reducer';

function* workerLogout() {
	try {
		const accessToken: string = yield select((state: RootStore) => state.auth.token.accessToken);
		const refreshToken: string = yield select((state: RootStore) => state.auth.token.refreshToken);
		yield call(API.post, '/api/v1/auth/logout', {
			accessToken,
			refreshToken,
		});
	} catch (error) {
		Alerter.error(error as SagaError);
	} finally {
		yield put(logoutSuccess());
	}
}

export default function* watchLogoutSaga(): Generator<ForkEffect<never>, void, unknown> {
	yield takeEvery(logoutRequest.type, workerLogout);
}
