import React from 'react';
import { Radio, Text, TextProps } from '@chakra-ui/react';
import { colors } from '@/theme/colors';

interface ICustomRadioButton {
	value: string;
	title: string;
	titleProps?: TextProps;
}

export const CustomRadioButton: React.FC<ICustomRadioButton> = ({ value, title, titleProps }) => {
	return (
		<Radio
			value={value}
			style={{
				border: `2px solid ${colors.primary}`,
			}}
			sx={{
				position: 'relative',
				width: '24px',
				height: '24px',
				bg: `2px solid ${colors.primary}`,
				_checked: {
					bg: `${colors.primary}`,
				},
			}}
		>
			<Text {...titleProps}>{title}</Text>
		</Radio>
	);
};
