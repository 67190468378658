import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { HistoryItem, Loader } from '@/components';
import { colors } from '@/theme/colors';
import { normalizePrice } from '@/utils';
import { RootStore } from '@/store';
import { UserDetailsPayload } from '../types';

const pageSize = 10;

interface IRefillsTab {
	onGetRefillsData: (payload: UserDetailsPayload) => void;
	onResetRefillsUserData: () => void;
	userId: number;
}

export const RefillsTab: React.FC<IRefillsTab> = ({ userId, onResetRefillsUserData, onGetRefillsData }) => {
	const [page, setPage] = useState(1);

	const { data, totalCount, sumAmount, loading } = useSelector((state: RootStore) => state.withdrawManagement.userRefills);

	const onPageChange = () => {
		setPage(prev => prev + 1);
	};

	const hasMore = useMemo(() => data.length < totalCount, [data.length, totalCount]);

	useEffect(() => {
		onGetRefillsData({
			currentPage: 1,
			getMore: false,
			userId,
			pageSize,
		});
		setPage(1);

		return () => {
			onResetRefillsUserData();
		};
	}, []);
	useEffect(() => {
		if (page !== 1) {
			onGetRefillsData({
				currentPage: page,
				getMore: true,
				userId,
				pageSize,
			});
		}
	}, [page]);

	const initialDataLoading = !data.length && loading;

	return (
		<Box>
			<VStack alignItems="flex-start" spacing="16px">
				<Text>Sum of refilled credits:</Text>
				{initialDataLoading ? (
					<Skeleton h="40px" width="150px" borderRadius="10px" startColor={colors.bgMain} endColor={colors.green[600]} />
				) : (
					<Text fontSize="32px" lineHeight="40px" fontWeight={700} color={colors.green[600]}>
						+ {normalizePrice(sumAmount)}
					</Text>
				)}
			</VStack>
			<InfiniteScroll
				dataLength={data.length}
				next={() => {
					if (!loading) {
						onPageChange();
					}
				}}
				hasMore={hasMore}
				loader={
					page !== 1 &&
					loading && (
						<Flex h="73px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
							<Loader centerHeight="40px" spinnerSize="sm" />
						</Flex>
					)
				}
				height="auto"
				className="infinite-scroll-section"
				style={{
					maxHeight: '230px',
					marginTop: '20px',
					paddingRight: data.length > 3 ? '4px' : '0px',
				}}
			>
				{loading && page === 1 ? (
					<Flex h="226px" mt="4px" bg={colors.bgMain} justify="center" align="center" borderRadius="10px" overflow="hidden">
						<Loader centerHeight="50px" spinnerSize="md" />
					</Flex>
				) : (
					<>
						{data.map(item => (
							<HistoryItem
								key={item.id}
								date={item.date}
								valueBefore={normalizePrice(item.balanceBefore)}
								valueAfter={`+${normalizePrice(item.actionAmount)}`}
								titleBefore="Balance before"
								titleAfter="Refill"
								balanceAfterValue={normalizePrice(item.balanceAfter)}
								exchangeColor={colors.green[600]}
								containerProps={{
									mt: '4px',
								}}
								arrowBack
							/>
						))}
					</>
				)}
			</InfiniteScroll>
		</Box>
	);
};
