import React, { useEffect, useState } from 'react';
import { Box, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { CloseIcon } from '@/assets/icons';
import { CustomSelect } from '@/components';
import { colors } from '@/theme/colors';
import { CommissionOptions } from '@/constants';
import { SelectOption } from '@/types';
import { RootStore } from '@/store';
import { CommissionValueType } from '@/enums';
import { CommissionEditPayload } from '../types';
import { FixedRateEditTab, PercentageEditTab } from '../tabs';

interface IEditCommissionModal {
	isOpen: boolean;
	onClose: () => void;
	onSubmitHandler: (payload: CommissionEditPayload) => void;
	onResetState: () => void;
}

export const EditCommissionModal: React.FC<IEditCommissionModal> = ({ isOpen, onClose, onSubmitHandler, onResetState }) => {
	const [selectedOption, setSelectedOption] = useState<SelectOption>({
		label: '',
		value: 0,
	});

	const { currentCommission } = useSelector((state: RootStore) => state.commission.commissionInfo);
	const { loading, success } = useSelector((state: RootStore) => state.commission.updateCommission);
	const { commissionTypeId, value: commissionValue } = currentCommission || {};

	useEffect(() => {
		if (isOpen) {
			setSelectedOption({
				label: '',
				value: commissionTypeId ? commissionTypeId : 1,
			});
		}
	}, [isOpen, currentCommission]);

	useEffect(() => {
		if (success) {
			onClose();
			onResetState();
		}
	}, [success]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered blockScrollOnMount>
			<ModalOverlay backdropFilter="blur(3px)" />
			<ModalContent
				overflow="hidden"
				bg={colors.green[300]}
				border={`1px solid ${colors.green[100]}`}
				borderRadius="10px"
				p="24px"
				boxShadow="none"
			>
				<ModalHeader fontWeight="500" fontSize="20px" p="0" pr="34px" color={colors.white[100]}>
					Edit Commission
				</ModalHeader>
				<ModalCloseButton
					top="24px"
					right="24px"
					w="30px"
					h="30px"
					bg={colors.primary}
					borderRadius="8px"
					color={colors.black[100]}
					_hover={{
						opacity: '0.75',
					}}
					_active={{}}
				>
					<CloseIcon />
				</ModalCloseButton>

				<Box maxW="160px" mt="24px">
					<CustomSelect
						value={CommissionOptions.find(option => option.value === selectedOption?.value)}
						onChange={(value: any) => {
							setSelectedOption(value);
						}}
						placeholder="Select"
						options={CommissionOptions}
						isSearchable={false}
					/>
				</Box>

				{selectedOption?.value === CommissionValueType.PERCENTAGE ? (
					<PercentageEditTab
						commissionValue={commissionValue && commissionTypeId === CommissionValueType.PERCENTAGE ? commissionValue : 0}
						loading={loading}
						isModalOpen={isOpen}
						commissionTypeId={selectedOption?.value}
						onClose={onClose}
						onSubmitHandler={(newCommissionValue: number) =>
							onSubmitHandler({
								commissionType: CommissionValueType.PERCENTAGE,
								commissionValue: newCommissionValue,
							})
						}
					/>
				) : null}
				{selectedOption?.value === CommissionValueType.FIXED_RATE ? (
					<FixedRateEditTab
						commissionValue={commissionValue && commissionTypeId === CommissionValueType.FIXED_RATE ? commissionValue : 0}
						loading={loading}
						isModalOpen={isOpen}
						onClose={onClose}
						commissionTypeId={selectedOption?.value}
						onSubmitHandler={(newCommissionValue: number) =>
							onSubmitHandler({
								commissionType: CommissionValueType.FIXED_RATE,
								commissionValue: newCommissionValue,
							})
						}
					/>
				) : null}
			</ModalContent>
		</Modal>
	);
};
