import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Commission from './pages/Commissions/Commission.page';
import SignIn from './pages/SignIn/SignIn.page';
import { DashboardLayout } from './components';
import WithdrawManagement from './pages/WithdrawManagement/WithdrawManagement.page';
import { ProtectedRoute } from '@/components/ProtectedRoute/ProtectedRoute';
import { ForgotPasswordContainer } from '@/pages/ForgotPassword/ForgotPassword.container';
import { ResetPasswordContainer } from '@/pages/ResetPassword/ResetPassword.container';
import Users from '@/pages/Users/Users.page';

const App: React.FC = () => {
	return (
		<Routes>
			<Route path="/sign-in" element={<SignIn />} />
			<Route path="/forgot-password" element={<ForgotPasswordContainer />} />
			<Route path="/reset-password" element={<ResetPasswordContainer />} />
			<Route
				path="/"
				element={
					<ProtectedRoute>
						<DashboardLayout />
					</ProtectedRoute>
				}
			>
				<Route index={true} element={<Commission />} />
				<Route
					path="/withdraw-management"
					element={
						<ProtectedRoute>
							<WithdrawManagement />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/users"
					element={
						<ProtectedRoute>
							<Users />
						</ProtectedRoute>
					}
				/>
			</Route>
		</Routes>
	);
};

export default App;
