import React from 'react';
import { Grid } from '@chakra-ui/react';
import { colors } from '@/theme/colors';
import { WithdrawManagementItem } from '../types';
import { TableCell } from './TableCell';
import { getNearestWithdrawalStatusProps } from '../utils';

interface ITableRow extends WithdrawManagementItem {
	onSelectUser: () => void;
}

export const TableRow: React.FC<ITableRow> = ({ email, firstName, lastName, onSelectUser, userId, withdrawalStatus }) => {
	const formattedStatus = withdrawalStatus ? 'Available' : 'Canceled';
	const currentWithdrawalStatus = getNearestWithdrawalStatusProps(withdrawalStatus?.id);
	return (
		<Grid
			templateColumns="100px 100px 180px 80px 154px"
			bg={colors.bgMain}
			gap="50px"
			w="100%"
			borderRadius="10px"
			px="16px"
			mt="4px"
			transition="background 0.3s ease"
			_hover={{
				background: colors.green[400],
				cursor: 'pointer',
			}}
			onClick={onSelectUser}
		>
			<TableCell title={firstName || '-'} />
			<TableCell title={lastName || '-'} />
			<TableCell title={email} />
			<TableCell title={userId} />
			<TableCell title={currentWithdrawalStatus.title} />
		</Grid>
	);
};
