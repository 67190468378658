import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { InputText } from '@/components';
import { useForm } from 'react-hook-form';
import { resetPasswordResolver } from '@/validation/reset-password.resolver';
import { ResetPasswordForm } from '@/types';

interface IResetPassword {
	onReset: (data: ResetPasswordForm) => void;
	loading: boolean;
}
export const ResetPassword: React.FC<IResetPassword> = ({ onReset, loading }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordForm>({
		resolver: resetPasswordResolver,
	});
	const onSubmit = (data: ResetPasswordForm) => {
		onReset(data);
	};
	return (
		<Flex h={'100vh'} alignItems="center" justifyContent="center" mb={{ base: '0px', md: '60px' }} mt={{ base: '0px', md: '20px' }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex
					direction="column"
					w={{ base: '100%', md: '445px' }}
					borderRadius={{ base: '0px', md: '16px' }}
					p={{ base: '24px', md: '40px' }}
					mx={{ base: '0px', md: '100px' }}
					bg={'#1F2733'}
					boxShadow={'base'}
				>
					<Text mb={'22px'} fontSize="xl" color={'white'} fontWeight="bold" textAlign="center">
						Reset password
					</Text>

					<InputText
						{...register('password')}
						label={'Enter password'}
						type="password"
						borderRadius={'12px'}
						border={'1px solid'}
						borderColor={'#ffffff29'}
						_hover={{}}
						_focusVisible={{}}
						minH={'48px'}
						fontSize={'14px'}
						mb={'20px'}
						p={'0 16px'}
						bg={'inherit'}
						placeholder={'Enter password'}
						errorMsg={errors.password?.message}
					/>
					<InputText
						{...register('confirmPassword')}
						label={'Confirm password'}
						type="password"
						borderRadius={'12px'}
						border={'1px solid'}
						borderColor={'#ffffff29'}
						_hover={{}}
						_focusVisible={{}}
						minH={'48px'}
						fontSize={'14px'}
						mb={'20px'}
						p={'0 16px'}
						bg={'inherit'}
						placeholder={'Enter password again'}
						errorMsg={errors.confirmPassword?.message}
					/>
					<Button
						isLoading={loading}
						type="submit"
						fontSize="10px"
						bg="teal.300"
						w="100%"
						h="45"
						borderRadius={'15px'}
						color="white"
						_hover={{
							bg: 'teal.200',
						}}
						_active={{
							bg: 'teal.400',
						}}
					>
						SEND
					</Button>
				</Flex>
			</form>
		</Flex>
	);
};
