export type UsersItem = {
	id: number;
	name: string;
	userName: string;
	email: string;
	credits: number;
	phone: string;
	address: string;
	wins: number;
	losses: number;
	isBot: boolean;
	isBlocked: boolean;
};

export type UsersResponse = {
	totalCount: number;
	users: UsersItem[];
};

export type UserMatchHistoryResponse = {
	count: number;
	gameStatistics: StatItem[];
};

export enum SearchType {
	Name = 'By name',
	Email = 'By email',
}
export enum RoleType {
	HUMAN = 'Human',
	BOT = 'Bot',
}

export enum StateType {
	ACTIVE = 'Active',
	BLOCKED = 'Blocked',
}

export type StatItem = {
	startTime: string;
	endTime: string;
	deaths: number;
	kills: number;
	assists: number;
	balance: number;
	gameResultId: number;
};
