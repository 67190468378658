import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponseEmpty, SagaError } from '@/types';
import API from '../../../api/api.base';
import { resetPasswordEmailError, resetPasswordEmailRequest, resetPasswordEmailSuccess } from '../../reducers/auth.reducer';
import { Alerter, History } from '@/utils';

function* workerResetPasswordEmail(action: ReturnType<typeof resetPasswordEmailRequest>) {
	try {
		const result: ApiResponseEmpty = yield call(API.post, '/api/v1/auth/reset-password-email', action.payload);
		if (result.success) {
			yield put(resetPasswordEmailSuccess());
			History.push('/');
			Alerter.success(result.messages[0].message);
		} else {
			yield put(resetPasswordEmailError(result.errors));
			Alerter.error(result.errors[0].message);
		}
	} catch (error) {
		Alerter.error(error as SagaError);
	}
}

export default function* watchResetPasswordEmail() {
	yield takeEvery(resetPasswordEmailRequest.type, workerResetPasswordEmail);
}
