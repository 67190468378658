import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import commissionReducer from '@/pages/Commissions/store/reducer';
import withdrawManagementReducer from '@/pages/WithdrawManagement/store/reducer';
import usersReducer from '@/pages/Users/store/reducer';

export const rootReducer = combineReducers({
	auth: authReducer,
	commission: commissionReducer,
	withdrawManagement: withdrawManagementReducer,
	users: usersReducer,
});
