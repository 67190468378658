import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { persistor, store } from './store';
import App from './App';
import './index.css';
import { NavigateSetter } from '@/components/NavigateSetter/NavigationSetter';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ChakraProvider theme={theme}>
					<NavigateSetter />
					<App />
				</ChakraProvider>
			</PersistGate>
		</Provider>
	</BrowserRouter>,
);
