export enum WithdrawalsPayoutStatus {
	CANCELED = 'payout.canceled',
	CREATED = 'payout.created',
	FAILED = 'payout.failed',
	PAID = 'payout.paid',
	RECONCILIATION_COMPLETED = 'payout.reconciliation_completed',
	UPDATED = 'payout.updated',
}

export enum NearestWithdrawalStatusId {
	AVAILABLE = 1,
	CANCELLED = 2,
	CLOSED = 3,
}
